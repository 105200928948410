import MainContainer from 'components/containers/MainContainer'
import Footer from 'components/organisms/nav/Footer'
import Header from 'components/organisms/nav/Header'
import PWAnavbar from 'components/organisms/nav/MobileNavBar'
import { ROUTES } from 'consts'
import useUserDocFetch from 'hooks/useUserDocFetch'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

const AuthUserLayout = ({ children }) => {
  const router = useRouter()
  const { userDocFetch } = useUserDocFetch()

  const accessToken = useSelector(state => state?.pr?.user?.auth?.stsTokenManager?.accessToken)

  useEffect(() => {
    if (!accessToken) {
      router.replace(ROUTES.ROOT)

      return
    }

    userDocFetch()
  }, [accessToken])

  return (
    <>
      {/* overflow-x-hidden removed here ^ to stop page stutter */}
      <Header />

      {/* <UnVerifiedBanner /> moved to sit under Header*/}

      <MainContainer>{children}</MainContainer>

      {/* For Mobile View */}
      <PWAnavbar />

      {/* For Desktop View */}
      <Footer />
    </>
  )
}

export default AuthUserLayout
