import { FaMapLocationDot } from '@react-icons/all-files/fa6/FaMapLocationDot'
import { PiHandshake } from '@react-icons/all-files/pi/PiHandshake'
import { getBaseUrlFor } from 'utils/Url'

import Icons from './Icons'
import ROUTES from './Routes'

const HEADER = {
  APP_USER_NAVIGATION: [
    {
      title: 'Feed',
      path: ROUTES.USER_FEED,
      className: 'hidden lg:block btn btn-success btn-sm',
      icon: <Icons.IOutlineFeed size={16} />,
    },
    {
      title: 'Explore',
      path: ROUTES.EXPLORE,
      className: 'hidden lg:block btn btn-secondary btn-sm',
      icon: <FaMapLocationDot size={16} />,
    },
    {
      title: 'My Tickets',
      path: ROUTES.USER_TICKETS,
      className: 'hidden lg:block btn btn-primary btn-sm',
      icon: <Icons.TicketIcon size={16} />,
    },
    // {
    //   path: '',
    //   title: 'Separator',
    //   className: 'hidden lg:block',
    // },
    // {
    //   title: '',
    //   path: ROUTES.HOST_DASHBOARD,
    //   className: 'btn btn-sm btn-secondary',
    //   icon: (
    //     <BsArrowLeftRight
    //       size={16}
    //       className='custom-transition-a cursor-pointer fill-secondary'
    //     />
    //   ),
    // },
  ],

  NAVIGATION: [
    {
      title: 'Features',
      path: ROUTES.FEATURES.PRICING_COMPARISON,
      className: 'hidden lg:block',
      subMenu: [
        {
          title: 'Ticketing',
          path: ROUTES.FEATURES.TICKETING,
        },
        {
          title: 'Attendee Management',
          path: ROUTES.FEATURES.ATTENDEE_MANAGEMENT,
        },
        {
          title: 'Growth & Marketing',
          path: ROUTES.FEATURES.GROWTH_AND_MARKETING,
        },
      ],
    },
    {
      title: 'Explore',
      path: ROUTES.EXPLORE,
      className: 'hidden lg:block btn btn-secondary btn-sm',
      icon: <FaMapLocationDot size={16} />,
    },
    {
      title: 'Partner Up',
      path: getBaseUrlFor('partners'),
      className: 'hidden lg:block btn btn-primary btn-sm',
      icon: <PiHandshake size={16} />,
      external: true,
    },
  ],
}

export default HEADER
