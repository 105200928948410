import React from 'react'

const errorMessages = [
  '🚨 Oops! Looks like our app took a coffee break ☕️. Please refresh!',
  '💥 Well, that just happened. Looks like we broke something 🤷‍♂️.',
  "🔧 Whoops! A wrench got stuck in the gears. We're on it!",
  "🙈 This is awkward... Something went wrong, but we're pretending it didn't.",
  "😬 Uh-oh! Our code must've missed its morning coffee. Try again?",
  '🤖 Our robots are on strike. Please refresh while we negotiate.',
  '🧠 Our app just had a brain freeze. Give it a moment and try again!',
  '🚧 Detour ahead! Looks like we hit a digital pothole.',
  '😅 Something went wrong, but we’re going to blame the intern.',
  '🐞 A bug just showed up uninvited. We’re sending it home!',
]

function getRandomErrorMessage() {
  const index = Math.floor(Math.random() * errorMessages.length)
  return errorMessages[index]
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.error('@getDerivedStateFromError', error)

    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo)
    console.error(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='card col-span-full mx-4 block !bg-error/50'>
          {/* <h2> This sector is having a bad day! 🔥</h2> */}
          <h2>{getRandomErrorMessage()}</h2>
          <span className='text-sm'>Error Code:{this?.props?.name || 'Unknown'}</span>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
