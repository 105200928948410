import Icons from './Icons'
import ROUTES from './Routes'
import { googleMeetScheduleLink } from './Urls'

const FOOTER_LINKS = {
  HOSTING: [
    {
      label: 'Host your events',
      link: ROUTES.HOSTING.HOST_YOUR_EVENT,
    },
    {
      label: 'Sell tickets online',
      link: ROUTES.HOSTING.SELL_TICKETS_ONLINE,
    },
    {
      label: 'Host Responsibly',
      link: ROUTES.HOSTING.HOST_RESPONSIBLY,
    },
    // {
    //   label: 'Hosting Resources',
    //   link: ROUTES.HOSTING.HOSTING_RESOURCES,
    // },
    {
      label: 'Event Planning',
      // link: '/',
      link: ROUTES.HOSTING.EVENT_PLANNING,
    },
    {
      label: 'Verification ',
      link: ROUTES.LEGAL.BADGE_VERIFICATION_REQUIREMENTS,
    },
  ],
  FEATURES: [
    {
      label: 'Pricing',
      target: '_blank',
      link: ROUTES.FEATURES.PRICING_COMPARISON,
    },
    {
      label: 'Ticketing',
      target: '_blank',
      link: ROUTES.FEATURES.TICKETING,
    },
    {
      label: 'Attendee Management',
      target: '_blank',
      link: ROUTES.FEATURES.ATTENDEE_MANAGEMENT,
    },
    {
      label: 'Growth and Marketing',
      target: '_blank',
      link: ROUTES.FEATURES.GROWTH_AND_MARKETING,
    },
  ],
  SESSAMI: [
    {
      label: 'About Us',
      link: ROUTES.ABOUT_US,
    },
    {
      label: 'Contact',
      link: ROUTES.CONTACT,
    },
    {
      label: 'Careers',
      link: ROUTES.SESSAMI.CAREERS,
    },
    {
      label: 'Investors',
      link: ROUTES.SESSAMI.INVESTORS,
    },
  ],
  RESOURCES: [
    {
      label: 'Blogs',
      link: ROUTES.RESOURCES.BLOG,
    },
    // {
    //   label: 'Help',
    //   link: ROUTES.RESOURCES.HELP,
    // },
    {
      label: 'FAQs',
      link: ROUTES.RESOURCES.FAQS,
    },
    // {
    //   label: 'News Center',
    //   link: ROUTES.RESOURCES.NEWS_CENTER,
    // },
    {
      label: 'SiteMap',
      link: ROUTES.SITEMAP,
    },
    {
      label: 'Schedule a Meeting',
      link: googleMeetScheduleLink,
      target: '_blank',
    },
  ],

  LEGAL: [
    {
      label: 'Terms of Use',
      link: ROUTES.LEGAL.TERMS_OF_USE,
    },
    {
      label: 'Privacy Policy',
      link: ROUTES.LEGAL.PRIVACY_POLICY,
    },
    {
      label: 'Cookie Policy',
      link: ROUTES.LEGAL.COOKIE_POLICY,
    },
    {
      label: 'Billing and Refunds',
      link: ROUTES.LEGAL.BILLING,
    },
  ],

  SOCIAL: [
    // {
    //   label: 'Whatsapp',
    //   link: 'https://wa.me/447850153604',
    // },
    {
      label: 'Facebook',
      link: 'https://www.facebook.com/sessamiAPP/',
      // icon: <FaFacebook size={24} />,
      icon: <Icons.Facebook size={24} />,
    },
    // {
    //   label: 'Youtube',
    //   link: '/',
    // },
    {
      label: 'Instagram',
      link: 'https://www.instagram.com/sessamiapp/',
      icon: <Icons.Instagram size={24} />,
    },
    {
      label: 'LinkedIn',
      link: 'https://www.linkedin.com/company/sessami',
      icon: <Icons.LinkedIn size={24} />,
    },
  ],
}

export default FOOTER_LINKS
