export const ADMIN_BASE_URL = {
  DEVELOPMENT: 'https://dev.admin.sessami.co',
  BETA: 'https://beta.admin.sessami.co',
  PRODUCTION: 'https://admin.sessami.co',
}

export const MARKETPLACE_BASE_URL = {
  DEVELOPMENT: 'https://dev.sessami.co',
  BETA: 'https://beta.sessami.co',
  PRODUCTION: 'https://sessami.co',
}

export const PARTNERS_BASE_URL = {
  DEVELOPMENT: 'https://dev.partners.sessami.co',
  BETA: 'https://beta.partners.sessami.co',
  PRODUCTION: 'https://partners.sessami.co',
}

// TODO: Incorporate this with proper testing later
export const CLOUD_FUNCTIONS_BASE_URL = {
  EMULATOR: 'http://127.0.0.1:5001/sessami-dev/us-central1/',
  DEVELOPMENT: 'https://us-central1-sessami-dev.cloudfunctions.net/',
  PRODUCTION: 'https://us-central1-sessami-production.cloudfunctions.net/',
}

export const googleMeetScheduleLink =
  'https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ11RR46LetC-EHnLG71uyISlGnfEyXms3ELNzEeRUKGi1C01H2U95dpNp3mBtJMEISCd0PuniWB'
