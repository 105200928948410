// import { Analytics as VercelAnalytics } from '@vercel/analytics/react'
import ProductionContainer from 'components/containers/ProductionContainer'
import Head from 'next/head'

const Analytics = () => {
  const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID
  const GOOGLE_TAG_MANAGER_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID

  return (
    <ProductionContainer>
      <Head>
        {/* <!-- Google Analytics  --> */}
        {GOOGLE_ANALYTICS_ID && (
          <>
            <script
              async
              id='GOOGLE_ANALYTICS_ID'
              src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`}
            />

            <script
              async
              dangerouslySetInnerHTML={{
                __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GOOGLE_ANALYTICS_ID}');`,
              }}
            />
          </>
        )}

        {/* <!-- Google Tag manager  --> */}
        {GOOGLE_TAG_MANAGER_ID && (
          <script
            async
            id='GOOGLE_TAG_MANAGER_ID'
            dangerouslySetInnerHTML={{
              __html: `
            (function (w, d, s, l, i) {
            w[l] = w[l] || []; w[l].push({
            'gtm.start':
            new Date().getTime(), event: 'gtm.js'
            }); var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', '${GOOGLE_TAG_MANAGER_ID}');`,
            }}></script>
        )}

        {/* <!-- Meta Pixel Code --> */}
        <script
          async
          dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1557412331556011');
            fbq('track', 'PageView');              
              `,
          }}></script>
        <noscript>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            height='1'
            width='1'
            style={{ display: 'none' }}
            src='https://www.facebook.com/tr?id=1557412331556011&ev=PageView&noscript=1'
          />
        </noscript>
        {/* <!-- End Meta Pixel Code --> */}

        {/* Microsoft Clarity */}
        <script
          async
          type='text/javascript'
          dangerouslySetInnerHTML={{
            __html: `
            (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "nex3zsck1y");
        `,
          }}></script>
      </Head>

      {/* https://vercel.com/docs/concepts/analytics/quickstart */}
      {/* <VercelAnalytics /> */}
    </ProductionContainer>
  )
}

export default Analytics
