import cn from 'clsx'
import useEventStatus from 'hooks/useEventStatus'

// import { useSelector } from 'react-redux'
// import { getUserByEmail } from 'services/client'

const EventStatusBadge = ({ status = 'default', title }) => {
  const config = {
    soldout: {
      color: 'bg-error/80',
      title: 'Sold Out',
    },
    waitlist: {
      color: 'bg-warning/80',
      title: 'Waitlist Open',
    },
    today: {
      color: 'bg-success/80',
      title: 'Today',
    },
    ended: {
      color: 'bg-error/80',
      title: 'Ended',
    },
    default: {
      color: 'bg-primary/80',
      title: '',
    },
  }

  return (
    <span
      className={cn(
        'w-fit rounded-lg px-2 py-1 text-base-100',
        config[status].color,
        'backdrop-blur-sm'
      )}>
      {title || config[status].title}
    </span>
  )
}

const EventSocialProofBadge = ({ event }) => {
  const { soldOut, hasEnded, isHappeningToday, percentageSold } = useEventStatus(event)

  // const displayGuests = useSelector(s => s?.pr?.user?.info?.showGuestsOnEventDetail) ?? true
  // const eventHost = getUserByEmail(event?.host?.email)
  // const displayGuests = eventHost?.showGuestsOnEventDetail ?? true

  const badges = []

  if (soldOut && !hasEnded) {
    badges.push(<EventStatusBadge key='soldout' status='soldout' />)
  }

  if (isHappeningToday) {
    badges.push(<EventStatusBadge key='today' status='today' />)
  }

  if (hasEnded) {
    badges.push(<EventStatusBadge key='ended' status='ended' />)
  }

  //  Area 51

  if (percentageSold >= 80 && !soldOut && !hasEnded) {
    if (!percentageSold) return null
    badges.push(
      <EventStatusBadge
        key='percentage'
        status='soldout'
        title={`${percentageSold.toFixed(0)}% SoldOut`}
      />
    )
  }

  // This wasn't well perceived by the Venues. Re work this.
  // else if (percentageSold < 75 && !soldOut) {
  //   const ticketCount = event?.summary?.ticketCount
  //   if (!ticketCount) return null

  //   const title = `${ticketCount} ${hasEnded ? ' attended' : ' going'}`

  //   badges.push(<EventStatusBadge key='xgoing' status='default' title={title} />)
  // }

  return <div className='absolute right-3 top-4 flex gap-2'>{badges}</div>
}

export default EventSocialProofBadge
