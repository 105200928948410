export const getCurrencySymbol = (currencyCode: string) => {
  const currencyMap = {
    GBP: '£', // British Pound Sterling
    // USD: '$', // United States Dollar
    EUR: '€', // Euro
    // CAD: 'C$', // Canadian Dollar
    // JPY: '¥', // Japanese Yen
    // AUD: 'A$', // Australian Dollar
    // AED: 'د.إ', // United Arab Emirates Dirham
  }

  const uppercasedCode = currencyCode ? currencyCode.toUpperCase() : 'GBP'

  return currencyMap[uppercasedCode] || currencyMap['GBP']
}
