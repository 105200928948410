import MSG from 'consts/Messages'
import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore'
import { toast } from 'react-toastify'
import { db } from 'services/FirebaseClient'

export const createVenue = async (userId, payload) => {
  const docRef = doc(db, 'users', userId)

  try {
    await updateDoc(docRef, { venues: arrayUnion(payload) })

    // Return true if the updation is completed
    return true
  } catch (error) {
    console.error('User Info Update Failed!', error)
    toast.error(error.message)
  }
}

export const deleteVenue = async (host, row, callback = () => {}) => {
  const docRef = doc(db, 'users', host.id)

  try {
    await updateDoc(docRef, { venues: arrayRemove(row) })
    callback()
  } catch (err) {
    toast.error(MSG.ERROR.OPERATION_FAILED)
    console.log(err)
  }
}
