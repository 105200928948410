import { useDispatch, useSelector } from 'react-redux'
import { setUserLocation } from 'store/reducers'

export default function useCoordsFetcher() {
  const dispatch = useDispatch()
  // const now = new Date()
  const userLocation = useSelector(state => state?.pr?.user?.info?.userLocation) // TODO: fix this!

  const fetchCoords = () => {
    if (userLocation !== undefined) {
      if (userLocation.timestamp < new Date().getTime() / 1000 - 60) {
        navigator.geolocation.getCurrentPosition(position => {
          dispatch(
            setUserLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
              timestamp: new Date().getTime() / 1000,
            })
          )
          console.log('refreshed coords')
        })
      } else {
        console.log(`coords not stale`)
      }
    } else {
      navigator.geolocation.getCurrentPosition(position => {
        dispatch(
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            timestamp: new Date().getTime() / 1000,
          })
        )
        console.log('refreshed coords')
      })
    }
  }

  return { fetchCoords }
}
