export const injectSuffix = (url: string, suffix: string): string => {
  if (!url) return ''

  // console.log('url->', url)

  let base = ''
  let query = ''

  // Our images have a mix of .webp extensions and non-extensions, so we need to handle both cases
  // ? is used to denote query parameters and we need autogenerated tokens params to access images
  // eg: https://firebasestorage.googleapis.com/v0/b/sessami-58c65.appspot.com/o/VrmdTBgSBETJV9upvSQCbZyUD9v2%2F202fc7a8-bc68-4c13-bb52-3469d944d55d%2FeventImage4524a037-7ef3-4e01-bce0-ab680bfd2254_1280x1280?alt=media&token=41c5e301-95b2-4853-b9ae-41944ba49948

  const index1 = url.indexOf('.webp?')
  const index2 = url.indexOf('?')
  const index = index1 !== -1 ? index1 : index2

  base = url.substring(0, index)
  query = index >= 0 ? url.substring(index) : ''

  return `${base}${suffix}${query}`
}
