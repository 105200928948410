import { useEffect, useState } from 'react'

const DEVICE_WIDTHS = {
  mobile: 480,
  tablet: 768,
  laptop: 1024,
  desktop: 1280,
}

const useIsMobile = (deviceType = 'default') => {
  const defaultThreshold = 1024
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < (DEVICE_WIDTHS[deviceType] || defaultThreshold)
  )

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < (DEVICE_WIDTHS[deviceType] || defaultThreshold))
    }

    window.addEventListener('resize', handleResize)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [deviceType])

  return isMobile
}

export default useIsMobile
