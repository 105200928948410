// Firebase Configuration for Client Side Application
// This should not run (initialise) on server side
import FIREBASE_CONFIG from 'consts/Firebase'
import { getAnalytics } from 'firebase/analytics'
import { getApps, initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { Timestamp, getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

// Determine Browser (Client Side) Environment
const ALLOW_CLIENT_APP = getApps().length === 0 && typeof window !== 'undefined'

// Initialize Firebase on client side
const fbClientSideApp = ALLOW_CLIENT_APP ? initializeApp(FIREBASE_CONFIG) : null

// Firebase services
const auth = ALLOW_CLIENT_APP ? getAuth(fbClientSideApp) : null // share single auth across other services - storage, firestore etc
const storage = ALLOW_CLIENT_APP ? getStorage(fbClientSideApp) : null // storage is for media - images, files etc
const db = ALLOW_CLIENT_APP ? getFirestore(fbClientSideApp) : null // collections - events, users, orders
const analytics = ALLOW_CLIENT_APP ? getAnalytics(fbClientSideApp) : null // Enable analytics. https://firebase.google.com/docs/analytics/get-started

// const provider = new GoogleAuthProvider()

// if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
//   connectFirestoreEmulator(db, 'localhost', 8080)
//   console.info('Connected to localhost emulator')
// }

export { db, storage, auth, analytics, Timestamp }
