function isObjectEmpty(obj) {
  if (!obj) return true // 👈 null and undefined check

  return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype
}

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj))
}

export function deepCopyAdvanced(obj, hash = new WeakMap()) {
  if (Object(obj) !== obj) return obj // primitives
  if (hash.has(obj)) return hash.get(obj) // circular reference

  const result = new obj.constructor()
  hash.set(obj, result)

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      result[key] = deepCopy(obj[key], hash)
    }
  }
  return result
}

export function removeUndefinedKeys(obj) {
  const newObj = {}
  for (let key in obj) {
    if (obj[key] !== undefined) {
      newObj[key] = obj[key]
    }
  }
  return newObj
}

/*
 * This function recursively sanitizes an object for upload to Firebase Storage.
 * It replaces any undefined values with an empty string.
 * @param {Object} json - The object to sanitize
 * @returns {Object} - The sanitized object
 */
export const sanitizeObjForUpload = data => {
  if (data === undefined || data === null) return ''
  else if (Number.isNaN(data)) return 0
  else if (typeof data === 'object' && data !== null) {
    // Array
    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        data[i] = sanitizeObjForUpload(data[i]) // recursion
      }
    }
    // Object
    else {
      let newData = { ...data } // create a new object and copy properties
      for (let key in newData) {
        newData[key] = sanitizeObjForUpload(newData[key]) // recursion
      }

      return newData
    }
  }

  return data
}

export default isObjectEmpty
