import type { AnyAction, PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

export interface ActiveEventState {
  id: string
  name: string
}

export interface ChartsState {
  activeEvent: ActiveEventState
  activeChartType: string
  // Add more state types here accordingly in the future
}

const initialState: ChartsState = {
  activeEvent: {
    id: '',
    name: '',
  },
  activeChartType: 'EventTicketSales',
}

export const chartSlice = createSlice({
  name: 'charts',
  initialState,
  reducers: {
    // Set active Event on Individual Event Sales graph
    setActiveEventOnIESC: (state, action: PayloadAction<ActiveEventState>) => ({
      ...state,
      activeEvent: action.payload,
    }),

    // Set active chart type on Individual Event Sales graph
    setActiveChartTypeOnIESC: (state, action: PayloadAction<string>) => ({
      ...state,
      activeChartType: action.payload,
    }),

    resetCharts: () => initialState,
  },
  //   extraReducers: {
  //   [HYDRATE]: (state, action) => ({
  //     ...state,
  //     ...action.payload,
  //   }),
  // },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      return {
        ...state,
        ...action.payload,
      }
    })
  },
})

export const { setActiveEventOnIESC, setActiveChartTypeOnIESC, resetCharts } = chartSlice.actions

export default chartSlice.reducer
