// Structured Data for events.
// See https://developers.google.com/search/docs/data-types/event
// See https://schema.org/Event
import { DATE_TIME_SEO_FORMAT } from 'consts'
import Head from 'next/head'
import React from 'react'
import { getEventSchemaAddressObject } from 'utils/Address'
import { formatDateTime } from 'utils/DateTime'

const SeoEvent = ({ event }) => {
  if (!event) return null

  const eventImagesArr = event?.images?.length ? event.images.map(i => i.url) : [event?.image || '']
  const addressComponents = event?.venue?.address?.[0]?.address_components || []

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Event',
    name: event?.name || '',
    startDate: formatDateTime(event?.date, DATE_TIME_SEO_FORMAT),
    endDate: formatDateTime(event?.date, DATE_TIME_SEO_FORMAT),
    eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
    eventStatus: 'https://schema.org/EventScheduled',
    location: {
      '@type': 'Place',
      name: event?.venue?.formattedAddress || '',
      address: getEventSchemaAddressObject(addressComponents),
    },
    image: eventImagesArr,
    description: event?.description || '',

    // TODO: Add Offers
    // offers: {
    //   '@type': 'Offer',
    //   url: 'https://www.example.com/event_offer/12345_201803180430',
    //   price: '30',
    //   priceCurrency: 'USD',
    //   availability: 'https://schema.org/InStock',
    //   validFrom: '2024-05-21T12:00',
    // },

    // TODO: Add Performer
    // performer: {
    //   '@type': 'PerformingGroup',
    //   name: 'Kira and Morrison',
    // },

    // TODO: Add Host Info
    organizer: {
      '@type': 'Organization',
      name: event?.host?.hostTitle || '',
      url: `${process.env.NEXT_PUBLIC_PRODUCTION_URL}/h/${event?.hostId}`,
    },
  }

  // console.log('SeoEvent', event, schema)

  return (
    <Head>
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
      />
    </Head>
  )
}

export default SeoEvent
