const SEO_DEFAULTS = {
  displayTitle: 'SESSAMI',
  description:
    'The grass roots event management and ticketing software, Grow your audience and your events.',
  imageURL: process.env.NEXT_PUBLIC_PRODUCTION_URL + '/assets/icons/logo-type-black.png',
  url: process.env.NEXT_PUBLIC_PRODUCTION_URL || 'https://sessami.co',

  keywords:
    // general
    'Sessami, Sessami Events, Event Management, Ticket Management,' +
    // for hosts
    'Event Hosting Tool, Event Management Tools, Event Marketing, Event Promotion, Event Ticketing, Event Ticketing Software, Event Ticketing System, Event Ticketing Website, Event Ticketing Websites, Event Tickets, Event Tickets Online, Event Tickets Website, Event Tickets Websites, Event Website, Event Websites, Eventbrite, Eventbrite Alternative, Eventbrite Competitors, Eventbrite' +
    // for attendees
    'Buy Event Tickets Online, Buy Concert Tickets Online, Buy Tickets Online, Buy Tickets,' +
    'Find Events in UK,',

  // allow indexing on production site
  followStatus: process.env.NEXT_PUBLIC_PRODUCTION_URL === 'https://sessami.co',
  indexStatus: process.env.NEXT_PUBLIC_PRODUCTION_URL === 'https://sessami.co',
  address: {
    addressLocality: 'Ashford',
    addressCountry: 'United Kingdom',
    postalCode: 'TN27 0JS',
    streetAddress: 'Kent, King Arthurs Court, Maidstone Rd, Charing',
  },
  formattedAddress:
    'Kent, King Arthurs Court, Maidstone Rd, Charing, Ashford TN27 0JS, United Kingdom',
}

const ORG_SCHEMA = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  '@id': '',
  name: SEO_DEFAULTS.displayTitle,
  url: SEO_DEFAULTS.url,
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'Ashford',
    addressCountry: 'United Kingdom',
    postalCode: 'TN27 0JS',
    streetAddress: 'Kent, King Arthurs Court, Maidstone Rd, Charing',
  },
  logo: 'https://sessami.co/assets/icons/logo-type-black.svg',
  description: SEO_DEFAULTS.description,
  sameAs: [
    'https://sessami.co',
    'https://partners.sessami.co',
    'https://twitter.com/sessamiAPP',
    'https://www.instagram.com/sessamiapp/',
    'https://www.linkedin.com/company/sessami',
    'https://www.youtube.com/channel/UCZ6pk8TYaIJEmBveCHiYsGw',
    'https://www.tiktok.com/@sessamiapp',
    'https://www.facebook.com/sessamiAPP/',
  ],
}

export { SEO_DEFAULTS, ORG_SCHEMA }
