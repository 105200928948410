import MSG from 'consts/Messages'
import {
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore'
import { toast } from 'react-toastify'
import { db } from 'services/FirebaseClient'

// #############################################################################
// #                                CREATE                                     #
// #############################################################################
export const createEvent = async (eventId, payload) => {
  const docRef = doc(db, 'events', eventId)

  try {
    await setDoc(docRef, { ...payload, createdAt: serverTimestamp() })
  } catch (err) {
    console.log('Error creating duplicate event', err)
    throw err
  }
}

// #############################################################################
// #                                READ                                       #
// #############################################################################
export const readEventsByHostId = async (hostId, callback = () => {}) => {
  if (!hostId) return null

  const eventRef = query(collection(db, 'events'), where('hostId', '==', hostId))

  try {
    onSnapshot(eventRef, querySnapshot => {
      const events = querySnapshot.docs.map(doc => doc.data())
      callback(events)
    })
  } catch (error) {
    console.error('Error @fetchAllPublicEvents', error)
  }
}

export const readEventByEventId = async eventId => {
  if (!eventId) return null

  const eventRef = query(collection(db, 'events'), where('eventId', '==', eventId))

  try {
    const snapshot = await getDocs(eventRef)
    const events = snapshot.docs.map(doc => doc.data())
    return events[0]
  } catch (error) {
    console.error('Error fetching event details: ', error)
  }
}

// #############################################################################
// #                                UPDATE                                     #
// #############################################################################
export const updateEventArchivalStatus = async eventId => {
  if (!eventId) return

  const docRef = doc(db, 'events', eventId)
  await updateDoc(docRef, { isArchived: true })
    .then(() => {
      toast.success(MSG.SUCCESS.SELLER.EVENT.ARCHIVED, { toastId: 'event-archived' })
    })
    .catch(e => {
      toast.error(MSG.ERROR.SELLER.EVENT_ARCHIVAL_FAIL, { toastId: 'event-archival-failed' })
      console.log('Event Archival Error:', e)
    })
}

export const updateEventPublishedStatus = async (eventId, showToast = true) => {
  if (!eventId) return

  const docRef = doc(db, 'events', eventId)
  await updateDoc(docRef, { isPublished: false })
    .then(() => {
      showToast &&
        toast.success(MSG.INFO.SELLER.UNPUBLISH_IN_DELETION, { toastId: 'unpublish-deletion' })
    })
    .catch(e => {
      toast.error(MSG.ERROR.SELLER.EVENT_DELETION_FAIL, { toastId: 'unpublish-deletion-fail' })
      console.log('Event Unpublishing Error:', e)
    })
}

export const addQuestionToSurvey = async (eventId, formInput, callback = () => {}) => {
  try {
    const docRef = doc(db, 'events', eventId)
    await updateDoc(docRef, { survey: arrayUnion(formInput) })
    callback()
    console.log('Question added successfully')
  } catch (error) {
    toast.error('Error adding question to survey', error)
  }
}

export const removeQuestionFromSurvey = async (eventId, question) => {
  try {
    const docRef = doc(db, 'events', eventId)
    await updateDoc(docRef, { survey: arrayRemove(question) })
    console.log('Question removed successfully')
  } catch (error) {
    toast.error('Error removing question from survey', error)
  }
}

export const addScannerToEvent = async (eventId, email) => {
  try {
    const docRef = doc(db, 'events', eventId)

    const payload = {
      // name: 'John Doe',
      email,
    }

    await updateDoc(docRef, { scanners: arrayUnion(payload) })
    console.log('Scanner added successfully')
  } catch (error) {
    toast.error('Error adding scanner', error)
  }
}

export const removeScannerFromEvent = async (eventId, scanner) => {
  try {
    const docRef = doc(db, 'events', eventId)
    await updateDoc(docRef, { scanners: arrayRemove(scanner) })
    console.log('Scanner removed successfully')
  } catch (error) {
    console.error('Error removing scanner', error)
    // Handle errors as needed
  }
}

export const updateWaitlistInDB = async (emailTo, event) => {
  try {
    let thisWaiter = event.waitList.find(wl => wl.email === emailTo)

    if (thisWaiter?.sentEmail) {
      toast.error(MSG.ERROR.SELLER.EMAIL_SENT_ALREADY, { toastId: 'email-sent-already' })
      return
    }

    let freshEntry = { ...thisWaiter, sentEmail: true }
    let filteredWaiters = event.waitList.filter(wl => wl.email !== emailTo)
    let freshWaiters = [...filteredWaiters, freshEntry]

    const docRef = doc(db, 'events', event.eventId)
    await updateDoc(docRef, { waitList: freshWaiters })

    toast.success(MSG.SUCCESS.SELLER.EMAIL_SENT, { toastId: 'email-sent' })
  } catch (error) {
    console.error('Error updating waitlist in database', error)
    toast.error(MSG.ERROR.OPERATION_FAILED)
  }
}

export const adjustEventsTicketCount = async (event, reducedFreeTicketsArr) => {
  try {
    const docRef = doc(db, 'events', event?.eventId)

    const updatedTickets = event?.tickets.map(t => {
      if (t?.price > 0) return t // skip paid tickets

      const individualTicketType = reducedFreeTicketsArr?.find(i => i?.id === t?.id)

      if (individualTicketType) {
        const updatedSold = Math.max(t?.sold - individualTicketType?.count, 0)

        return { ...t, sold: updatedSold }
      } else {
        return t
      }
    })

    await updateDoc(docRef, { tickets: updatedTickets })

    return 'Event updated successfully'
  } catch (error) {
    console.log('error', error)
  }
}
// #############################################################################
// #                                DELETE                                     #
// #############################################################################
export const deleteEventById = async eventId => {
  if (!eventId) return

  const docRef = doc(db, 'events', eventId)
  await deleteDoc(docRef)
    .then(() => {
      toast.success(MSG.SUCCESS.SELLER.EVENT.DELETED, { toastId: 'event-deleted' })
    })
    .catch(e => {
      toast.error(MSG.ERROR.SELLER.EVENT_DELETION_FAIL, { toastId: 'event-deletion-failed' })
      console.log('error in event deletion::', e)
    })
}
