import withErrorBoundary from 'components/templates/WithErrorBoundary'
import React from 'react'

import Container from './Container'
import GridContainer from './GridContainer'
import SectionContainer from './SectionContainer'

const ReusableGrid = ({ children, className = '', id = '' }) => {
  return (
    <SectionContainer id={id} className='animate-fade-up'>
      <Container>
        <GridContainer className={`${className}`}>{children}</GridContainer>
      </Container>
    </SectionContainer>
  )
}

export default withErrorBoundary(ReusableGrid)
