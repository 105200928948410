// GlobalStore
import { configureStore } from '@reduxjs/toolkit'
import { createWrapper } from 'next-redux-wrapper'
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync'

import { loaderUIMiddleware } from './middleware'
import rootReducer from './reducers'
// import storage from 'redux-persist/lib/storage'
import storage from './storage'

const blacklist = [FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE]

// individual reducers -> rootReducer -> persistedReducer -> store
const makeStore = () => {
  const isServer = typeof window === 'undefined'

  //If it's on server side, create a store
  if (isServer) {
    return configureStore(
      {
        reducer: rootReducer,
      }
      // bindMiddleware([thunkMiddleware])
    )
  }

  //If it's on client side, create a store which will persist
  const { persistStore, persistReducer } = require('redux-persist')

  const persistConfig = {
    key: 'nextjs',
    storage, // if needed, use a safer storage
    blacklist: ['bigActionsConfirmation', 'confirmation', 'loading'],
  }

  // Create a new reducer with our existing reducer
  const pr = persistReducer(persistConfig, rootReducer)

  const reduxStateSyncConfig = {
    // ...pr,
    // https://github.com/aohua/redux-state-sync/issues/53#issuecomment-597652537
    blacklist: blacklist,

    // IMP: Do not change this
    // enforce a type, oneOf['native', 'idb', 'localstorage', 'node']
    broadcastChannelOption: { type: 'localstorage' },
  }

  const defaultMiddlewareConfig = {
    serializableCheck: false, // TODO: enable this in future
  }

  const middleware = getDefaultMiddleware =>
    getDefaultMiddleware(defaultMiddlewareConfig).concat([
      createStateSyncMiddleware(reduxStateSyncConfig),
      loaderUIMiddleware,
    ])

  const store = configureStore({
    reducer: { pr },
    middleware: middleware,
    devTools: process.env.NEXT_PUBLIC_APP_ENV !== 'production',
  })

  // Use initStateWithPrevTab to get existing state from other tabs
  initStateWithPrevTab(store)

  // This creates a persistor object & push that persisted object to .__persistor,
  // so that we can avail the persistability feature
  store.__persistor = persistStore(store)

  return store
}

// Export the wrapper & wrap the pages/_app.js with this wrapper only
// This allows to access store during SSR on Nextjs and Syncs it back during CSR
// not sure of the latter part
export const wrapper = createWrapper(makeStore)
