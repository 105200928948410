import { FaSearch } from '@react-icons/all-files/fa/FaSearch'
import Fuse from 'fuse.js'
import useIsMobile from 'hooks/useIsMobile'
import React, { useEffect, useState } from 'react'

const SearchInput = ({ data, searchKeys, placeholder = 'Search', renderItem }) => {
  const [query, setQuery] = useState('')
  const [searchedItems, setSearchedItems] = useState([])
  const [areResultsVisible, setAreResultsVisible] = useState(false)

  const showResultsList = areResultsVisible && searchedItems?.length > 0

  const MAX_RESULTS = useIsMobile('mobile') ? 5 : 10

  const DEBOUNCE_DELAY = 500

  useEffect(() => {
    if (!query.trim()) {
      setAreResultsVisible(false)
      return
    }

    const timeoutId = setTimeout(() => {
      const fuse = new Fuse(data, {
        keys: searchKeys,
        threshold: 0.5,
      })

      const results = fuse
        ?.search(query)
        ?.slice(0, MAX_RESULTS)
        ?.map(result => result.item)

      setSearchedItems(results)
      setAreResultsVisible(true)
    }, DEBOUNCE_DELAY)

    return () => clearTimeout(timeoutId)
  }, [query, data, searchKeys])

  const handleInputChange = e => {
    setQuery(e.target.value)
  }

  const handleResultClick = () => {
    setQuery('')
    setAreResultsVisible(false)
  }

  return (
    <div className='dropdown relative !w-[32rem] outline-none'>
      <label
        tabIndex={0}
        role='button'
        className='input input-bordered flex h-9 items-center gap-2'>
        <input
          type='text'
          value={query}
          onChange={handleInputChange}
          className='grow'
          placeholder={placeholder}
        />
        <FaSearch size={16} className='text-gray-500' />
      </label>

      {showResultsList && (
        <ul
          onClick={handleResultClick}
          className='no-scrollbar dropdown-content absolute !z-50 mt-2 max-h-[90dvh] w-full overflow-auto rounded-lg bg-base-100 shadow-2xl'>
          {searchedItems?.map(item => (
            <li key={item?.id}>{renderItem(item)}</li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default SearchInput
