import type { AnyAction, PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

interface ConfirmationState {
  isOpen?: boolean
  title: string
  message: string
  onConfirm: () => void
  onCancel: () => void
}

const initialState = {
  isOpen: false,
  title: '',
  message: '',
  onConfirm: () => {},
  onCancel: () => {},
}

export const confirmationSlice = createSlice({
  name: 'confirmation',
  initialState,
  reducers: {
    openConfirmation: (state, action: PayloadAction<ConfirmationState>) => ({
      ...state,
      isOpen: true,
      title: action.payload.title,
      message: action.payload.message,
      onConfirm: action.payload.onConfirm,
      onCancel: action.payload.onCancel,
    }),

    closeConfirmation: () => initialState,
  },
  // extraReducers: {
  //   [HYDRATE]: (state, action) => ({
  //     ...state,
  //     ...action.payload,
  //   }),
  // },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      return {
        ...state,
        ...action.payload,
      }
    })
  },
})

export const { openConfirmation, closeConfirmation } = confirmationSlice.actions

export default confirmationSlice.reducer
