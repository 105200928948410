// ------------- Events Reducer -------------
import { createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

// #1 Initial state
const initialState = {
  events: [],
  allEvents: [],
}

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setEvents: (state, action) => ({ ...state, events: action?.payload || [] }),
    setAllEvents: (state, action) => ({ ...state, allEvents: action?.payload || [] }),
    //
    resetEvents: () => initialState,
  },
  // Special reducer for hydrating the state
  // extraReducers: {
  //   [HYDRATE]: (state, action) => {
  //     console.log('HYDRATE', state, action.payload)

  //     return {
  //       ...state,
  //       ...action.payload,
  //     }
  //   },
  // },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    })
  },
})

// Action creators are generated for each case reducer function
export const { setEvents, setAllEvents, resetEvents } = eventsSlice.actions

export default eventsSlice.reducer
