// import { useSelector } from 'react-redux'
import {
  getTotalEventTickets,
  getTotalEventTicketsSold,
  isEventHappeningToday,
  isEventSoldOut,
  isMajoritySoldOut,
  shouldPreventPurchase,
} from 'utils/Events'

const useEventStatus = event => {
  const venueSeatPlans = event?.venue?.seatPlans || []
  const venueCapacity =
    venueSeatPlans?.reduce((total, i) => parseInt(i?.rows) * parseInt(i?.columns) + total, 0) || 0

  const totalTickets = getTotalEventTickets(event?.tickets)
  const totalTicketsSold = getTotalEventTicketsSold(event?.tickets)
  const soldOut = isEventSoldOut(event, totalTickets, totalTicketsSold, venueCapacity)

  // TODO: Calculate isEventSoldOut based on orders collection data -kd
  const hasEnded = shouldPreventPurchase(event?.date, event?.endDateTime)
  const isMajoritySold = isMajoritySoldOut(event, totalTickets, totalTicketsSold, venueCapacity)
  const isHappeningToday = isEventHappeningToday(event?.date)
  const percentageSold = (totalTicketsSold / totalTickets) * 100

  return {
    percentageSold,
    isMajoritySold,
    isHappeningToday,
    soldOut,
    hasEnded,
  }
}

export default useEventStatus
