const FIREBASE_CONFIG = {
  apiKey: process.env.NEXT_PUBLIC_APIKEY || 'mock_key',
  authDomain: process.env.NEXT_PUBLIC_AUTHDOMAIN,
  projectId: process.env.NEXT_PUBLIC_PROJECTID,
  storageBucket: process.env.NEXT_PUBLIC_STORAGEBUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGINGSENDERID,
  appId: process.env.NEXT_PUBLIC_APPID,
  measurementId: process.env.NEXT_PUBLIC_MEASUREMENTID,
}

export const PATH_GENERATION_LIMIT = process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 1000 : 30
export const HOME_EVENTS_FETCH_LIMIT = process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 32 : 32
export const HOME_HOSTS_FETCH_LIMIT = process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 50 : 36
export const HOST_DETAIL_EVENTS_FETCH_LIMIT =
  process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 200 : 50
export const FETCH_LIMIT = process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 250 : 50
export const UPSELL_RECOMMENDATION_LIMIT = 16
export const UPSELL_RECOMMENDATION_PER_TYPE_LIMIT = 4
export const SALES_ORDERS_FETCH_LIMIT = process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 500 : 50
export const EVENT_LIST_PAGINATION_FETCH_LIMIT =
  process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 50 : 10
export const COMMENT_LIST_PAGINATION_FETCH_LIMIT = 10
export const MINIMAL_COMMENT_LIST_PAGINATION_FETCH_LIMIT = 3
export const GUEST_LIST_PAGINATION_FETCH_LIMIT =
  process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 500 : 50

export default FIREBASE_CONFIG
