import { EVENT_LIST_PAGINATION_FETCH_LIMIT } from 'consts/Firebase'
import MSG from 'consts/Messages'
import {
  Timestamp,
  collection,
  doc,
  getCountFromServer,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore'
import { toast } from 'react-toastify'
import { db } from 'services/FirebaseClient'

// #############################################################################
// #                                CREATE                                     #
// #############################################################################

// #############################################################################
// #                                READ                                       #
// #############################################################################
export const readEventswPagination = async (startsAfter = null) => {
  const baseQuery = [
    where('date', '>', Timestamp.now()),
    where('isPublished', '==', true),
    where('isPrivate', '==', false),
    where('isArchived', '==', false),
    where('isCancelled', '==', false),
    where('summary.occupancyPercent', '<', 100),
  ]

  const coll = collection(db, 'events')

  const countQuery = query(coll, ...baseQuery)

  const q = query(
    coll,
    ...baseQuery,
    orderBy('date'),
    startAfter(startsAfter),
    limit(EVENT_LIST_PAGINATION_FETCH_LIMIT)
  )

  try {
    const countSnapShot = await getCountFromServer(countQuery)
    const retrievedEventsCount = countSnapShot.data().count

    const querySnapshot = await getDocs(q)

    const allEvents = querySnapshot.docs.map(doc => doc.data())
    const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1]

    return { allEvents, lastVisibleDoc, retrievedEventsCount }
  } catch (error) {
    console.log(error.message)
    toast.error(error.message)
  }
}

export const readEventById = async eventId => {
  if (!eventId) return

  const q = query(collection(db, 'events'), where('eventId', '==', eventId))

  try {
    const snapshot = await getDocs(q)

    return snapshot.docs[0]?.data()
  } catch (err) {
    toast.error(MSG.ERROR.BUYER.FETCH_EVENT_DETAILS, { toastId: 'get-eventbyid-failed' })
    console.log('🚩 @readEventById', err.message)
  }
}

export const readEventByIdwRTU = async (eventId, callback) => {
  if (!eventId) return

  const q = query(collection(db, 'events'), where('eventId', '==', eventId))

  // with Real Time Updates (wRTU) via websockets
  // This is meant to be use on event detail page.
  onSnapshot(q, snapshot => callback(snapshot.docs[0]?.data()))
}

// export const readPublicEvents = async () => {
//   const q = query(
//     collection(db, 'events'),
//     where('isPublished', '==', true),
//     where('isPrivate', '==', false),
//     where('isArchived', '==', false),
//     limit(FETCH_LIMIT)
//   )

//   return getDocs(q)
//     .then(snapshot => {
//       const events = snapshot.docs.map(doc => doc.data())
//       console.log('Fetched Public Events', events)
//       return events
//     })
//     .catch(error => console.error('Error @readPublicEvents', error))
// }

export const readFutureEventsByHostId = async hostId => {
  if (!hostId) return null

  const eventRef = query(
    collection(db, 'events'),
    where('hostId', '==', hostId),
    where('date', '>=', Timestamp.now()),
    where('isPublished', '==', true),
    where('isPrivate', '==', false),
    where('isArchived', '==', false),
    where('isCancelled', '==', false)
  )

  try {
    const snapshot = await getDocs(eventRef)
    const events = snapshot.docs.map(doc => doc.data())
    return events
  } catch (error) {
    toast.error(MSG.ERROR.OPERATION_FAILED, { toastId: 'read-error' })
    console.error('Error @readFutureEventsByHostId', error)
    return []
  }
}

export const readPublicEventsCountByHostId = async (hostId, futureOnly = false) => {
  if (!hostId) return

  const baseQuery = query(
    collection(db, 'events'),
    where('hostId', '==', hostId),
    where('isPublished', '==', true),
    where('isPrivate', '==', false),
    where('isArchived', '==', false),
    where('isCancelled', '==', false)
  )

  const futureTrueQuery = query(baseQuery, where('date', '>=', Timestamp.now()))

  const finalQuery = futureOnly ? futureTrueQuery : baseQuery

  return getCountFromServer(finalQuery)
    .then(snapshot => snapshot.data().count)
    .catch(error => {
      console.log('Error @readPublicEventsCountByHostId', error)
      toast.error(error)
      return 0
    })
}
// #############################################################################
// #                                UPDATE                                     #
// #############################################################################
export const updateEvent = async (
  eventId,
  payload,
  callback = () => {},
  loading = {
    start: () => {},
    remove: () => {},
  }
) => {
  try {
    loading.start()
    const docRef = doc(db, 'events', eventId)
    await updateDoc(docRef, payload)
    callback()
  } catch (error) {
    console.error('Error updating event in database', error)
    toast.error(MSG.ERROR.OPERATION_FAILED, { toastId: 'operation-failed' })
  } finally {
    loading.remove()
  }
}

// #############################################################################
// #                                DELETE                                     #
// #############################################################################
