import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { db } from 'services/FirebaseClient'

export const fetchAllNotifications = async (callback = () => {}) => {
  const q = query(collection(db, 'notifications'), where('notificationId', '>', 0))

  try {
    onSnapshot(q, snapshot => {
      const notifications = snapshot.docs.map(doc => doc.data())
      callback(notifications.toSorted((a, b) => b.notificationId - a.notificationId))
    })
  } catch (error) {
    console.error('Error @fetchNotifications', error)
  }
}
