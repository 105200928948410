import cn from 'clsx'
import Link from 'next/link'

interface ButtonProps {
  children?: React.ReactNode
  onClick?: () => void
  className?: string
  icon?: React.ReactNode
  primary?: boolean
  secondary?: boolean
  href?: string
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  className = '',
  icon,
  primary,
  secondary,
  href,
  type = 'button',
  disabled = false,
  ...props
}: ButtonProps) => {
  if (href) {
    return (
      <Link
        {...props}
        href={href}
        className={cn('btn', primary && 'btn-primary', secondary && 'btn-secondary', className)}>
        {icon && <span className='text-sm'>{icon}</span>} {children}
      </Link>
    )
  }

  return (
    <button
      type={type}
      disabled={disabled}
      {...props}
      onClick={onClick}
      className={cn('btn', primary && 'btn-primary', secondary && 'btn-secondary', className)}>
      {icon && <span className='text-sm'>{icon}</span>} {children}
    </button>
  )
}

export default Button
