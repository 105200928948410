'use client'

import Container from 'components/containers/Container'
import GridContainer from 'components/containers/GridContainer'
import useKeyPress from 'hooks/useKeyPress'
import { useEffect, useState } from 'react'

// Grid Overlay for toggling grid system
const GridOverlay = () => {
  const [isGridActive, setGridStatus] = useState(false)
  // const [isGridActive, setGridStatus] = useState((process.env.APP_ENV = 'development'))

  // Track multi-key press
  const ctrlPress = useKeyPress('control') // key - ctrl
  const gPress = useKeyPress('g') // key - g

  useEffect(() => {
    // console.log('ctrlPress', ctrlPress)
    if (ctrlPress && gPress) setGridStatus(!isGridActive)
  }, [ctrlPress, gPress])

  return (
    <div id='grid-overlay' className='relative z-50 flex justify-center'>
      {isGridActive && (
        <Container className='pointer-events-none fixed z-50 h-screen text-center '>
          <GridContainer className='h-screen'>
            <div className='col-span-1 bg-secondary/30 opacity-40 '>1</div>
            <div className='col-span-1 bg-secondary/30 opacity-40 '>2</div>
            <div className='col-span-1 bg-secondary/30 opacity-40 '>3</div>
            <div className='col-span-1 bg-secondary/30 opacity-40 '>4</div>
            <div className='col-span-1 hidden bg-secondary/30 opacity-40   lg:block'>5</div>
            <div className='col-span-1 hidden bg-secondary/30 opacity-40   lg:block'>6</div>
            <div className='col-span-1 hidden bg-secondary/30 opacity-40   lg:block'>7</div>
            <div className='col-span-1 hidden bg-secondary/30 opacity-40   lg:block'>8</div>
            <div className='col-span-1 hidden bg-secondary/30 opacity-40   lg:block'>9</div>
            <div className='col-span-1 hidden bg-secondary/30 opacity-40   lg:block'>10</div>
            <div className='col-span-1 hidden bg-secondary/30 opacity-40   lg:block'>11</div>
            <div className='col-span-1 hidden bg-secondary/30 opacity-40   lg:block'>12</div>
          </GridContainer>
        </Container>
      )}
    </div>
  )
}

export default GridOverlay
