import { useDispatch, useSelector } from 'react-redux'
import { getUserByIdwRTU } from 'services/client/buyer'
import { setUserInfo } from 'store/reducers'

export default function useUserDocFetch() {
  const dispatch = useDispatch()
  const userId = useSelector(state => state?.pr?.user?.info?.id)

  const userDocFetch = () => {
    if (!userId) return null

    getUserByIdwRTU(userId, user => {
      dispatch(setUserInfo(user))
    })
  }

  return { userDocFetch }
}
