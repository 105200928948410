import { useDispatch, useSelector } from 'react-redux'
import { fetchAllNotifications, readOrdersByBuyerId, readPublicEvents } from 'services/client'
import { setAllEvents, setNotifications, setPurchaseOrders } from 'store/reducers'

export default function useFetcherForUser() {
  const dispatch = useDispatch()
  const userId = useSelector(state => state?.pr?.user?.info?.id)

  const now = new Date()
  now.setHours(now.getHours() - 3)

  // Fetch all purchase orders that belong to current user(buyer)
  const fetchAllMyOrders = async () => {
    if (!userId) return null

    readOrdersByBuyerId(userId, orders => {
      dispatch(setPurchaseOrders(orders))
    })
  }

  const fetchAllPublicEvents = async () => {
    readPublicEvents(events => {
      dispatch(setAllEvents(events))
    })
  }

  const fetchNotifications = async () => {
    fetchAllNotifications(notifications => {
      dispatch(setNotifications(notifications))
    })
  }

  return { fetchAllPublicEvents, fetchAllMyOrders, fetchNotifications }
}
