// ------------- UI Reducer -------------
import { createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

// #1 Initial state
const initialState = {
  info: {},
  auth: {},
  selectedEmails: {},
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state, action) => ({ ...state, info: action?.payload }),
    setUserAuth: (state, action) => ({ ...state, auth: action?.payload }),
    setSelectedEmails: (state, action) => ({ ...state, selectedEmails: action?.payload }),

    setUserLocation: (state, action) => ({
      ...state,
      info: {
        ...state.info,
        userLocation: action?.payload,
      },
    }),

    resetUser: () => initialState,
  },

  // Special reducer for hydrating the state
  // extraReducers: {
  //   [HYDRATE]: (state, action) => {
  //     return {
  //       ...state,
  //       ...action.payload.comments,
  //     }
  //   },
  // },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    })
  },
})

// Action creators are generated for each case reducer function
export const {
  setUserInfo,
  resetUser,
  setUserAuth,
  setSelectedEmails,
  //
  setUserLocation,
} = userSlice.actions

export default userSlice.reducer
