interface AddressComponent {
  long_name: string
  short_name: string
  types: string[]
}

interface EventAddress {
  '@type': string
  streetAddress: string
  addressLocality: string
  postalCode: string
  addressRegion: string
  addressCountry: string
}

interface MinifiedAddress {
  street_number?: string
  route?: string
  country?: string
  administrative_area_level_1?: string
  locality?: string
  postal_code?: string
  neighborhood?: string
}

const requiredTypes: (keyof MinifiedAddress)[] = [
  'street_number',
  'route',
  'country',
  'administrative_area_level_1',
  'locality',
  'postal_code',
  'neighborhood',
]

export const minifyAddress = (addressComponents?: AddressComponent[] | null): MinifiedAddress => {
  if (!addressComponents) return {}

  const miniAddress: MinifiedAddress = requiredTypes.reduce<MinifiedAddress>((acc, curr) => {
    const requiredElement = addressComponents.find(i => i.types.includes(curr))

    if (requiredElement) {
      return { ...acc, [curr]: requiredElement.long_name }
    }
    return acc
  }, {})

  console.log('miniAddress->', miniAddress)

  return miniAddress
}

export const getEventSchemaAddressObject = (
  addressComponents?: AddressComponent[] | null
): EventAddress | Record<string, never> => {
  if (!addressComponents || !addressComponents.length) return {}

  const getComponent = (type: string): string => {
    const component = addressComponents.find(comp => comp.types.includes(type))
    return component ? component.long_name : ''
  }

  const address: EventAddress = {
    '@type': 'PostalAddress',
    streetAddress: `${getComponent('street_number')} ${getComponent('route')}`,
    addressLocality: getComponent('postal_town'),
    postalCode: getComponent('postal_code'),
    addressRegion: getComponent('administrative_area_level_1'),
    addressCountry: getComponent('country'),
  }

  return address
}
