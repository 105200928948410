import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { wrapper } from 'store'

const ReduxProvider = ({ children, pageProps }) => {
  const { store } = wrapper.useWrappedStore(pageProps)

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={store.__persistor}>
        {children}
      </PersistGate>
    </Provider>
  )
}

export default ReduxProvider
