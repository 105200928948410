import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

type BigActionsConfirmationState = boolean

const initialState = {
  isOpen: false,
}

export const bigActionsConfirmation = createSlice({
  name: 'bigActionsConfirmation',
  initialState,
  reducers: {
    showConfirmation: (state, action: PayloadAction<BigActionsConfirmationState>) => ({
      ...state,
      isOpen: action.payload,
    }),

    hideConfirmation: () => initialState,
  },
  extraReducers: {
    [HYDRATE]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
})

export const { showConfirmation, hideConfirmation } = bigActionsConfirmation.actions

export default bigActionsConfirmation.reducer
