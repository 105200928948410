import { Timestamp } from 'firebase/firestore'

export const getCutOffTimestamp = (tense, days) => {
  const todayRef = new Date()
  const cutOff = new Date(todayRef)

  tense === 'past'
    ? cutOff.setDate(todayRef.getDate() - days)
    : cutOff.setDate(todayRef.getDate() + days)

  return Timestamp.fromDate(cutOff)
}
