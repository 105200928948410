import { FaCookie } from '@react-icons/all-files/fa/FaCookie'
import { FaCookieBite } from '@react-icons/all-files/fa/FaCookieBite'
import cn from 'clsx'
import Button from 'components/atoms/Button'
import ProductionContainer from 'components/containers/ProductionContainer'
import Heading from 'components/molecules/Heading'
import { MSG, ROUTES } from 'consts'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { setCookie, setIsCookieSet } from 'store/reducers'

const Cookie = ({ showCookieIcon = true }) => {
  const dispatch = useDispatch()
  const isCookieSet = useSelector(state => state?.pr?.ui?.isCookieSet)
  const cookieData = useSelector(state => state?.pr?.ui?.cookie)

  const [isCookieClicked, setIsCookieClicked] = useState(false)
  const [acceptedCookie, setAcceptedCookie] = useState({})
  const [performanceCookie, setPerformanceCookie] = useState(cookieData?.performance)
  const [statisticsCookie, setStatisticsCookie] = useState(cookieData?.statistics)

  const handleCookieAcceptance = action => {
    if (action === 'Accept All') {
      setAcceptedCookie({
        necessary: true,
        performance: true,
        statistics: true,
      })
    } else if (action === 'Accept Selected') {
      setAcceptedCookie({
        necessary: true,
        performance: performanceCookie,
        statistics: statisticsCookie,
      })
    }
  }

  useEffect(() => {
    if (Object.keys(acceptedCookie).length === 0) return

    localStorage.setItem('cookie accepted', JSON.stringify(acceptedCookie))

    toast.success(MSG.INFO.COOKIE_UPDATE, { toastId: 'update-cookie' })

    dispatch(setIsCookieSet(true))
    dispatch(setCookie(acceptedCookie))
    if (showCookieIcon) return
    document.getElementById('cookie-modal').close()
  }, [acceptedCookie])

  return (
    <ProductionContainer>
      <div
        className={cn(
          'card card-compact z-30 !bg-secondary !text-primary-content',
          'fixed right-4 md:mr-16',
          'custom-transition-a',
          (isCookieClicked && !isCookieSet) || !showCookieIcon
            ? 'bottom-28 md:bottom-6'
            : '-bottom-1/2'
        )}>
        <div className='card-body w-full'>
          <Heading title='Cookie Settings' />

          <p>
            Read our Policy{' '}
            <strong>
              <u>
                <Link href={ROUTES.LEGAL.COOKIE_POLICY}>here.</Link>
              </u>
            </strong>
          </p>

          <div className='flex flex-col md:flex-row md:gap-2 '>
            <label className='label cursor-pointer gap-2 '>
              <input type='checkbox' defaultChecked className='checkbox' disabled />
              <span className='label-text text-primary-content md:text-lg' disabled>
                Necessary
              </span>
            </label>

            <label className='label cursor-pointer gap-2'>
              <input
                type='checkbox'
                className='checkbox'
                value={performanceCookie}
                defaultChecked={cookieData?.performance}
                onChange={() => setPerformanceCookie(!performanceCookie)}
              />
              <span className='label-text text-primary-content md:text-lg'>Performance</span>
            </label>

            <label className='label cursor-pointer gap-2'>
              <input
                type='checkbox'
                className='checkbox'
                value={statisticsCookie}
                defaultChecked={cookieData?.statistics}
                onChange={() => setStatisticsCookie(!statisticsCookie)}
              />
              <span className='label-text text-primary-content md:text-lg'>Statistics</span>
            </label>
          </div>
          <div className='card-actions justify-center md:justify-end'>
            <Button
              className='btn-base-100 btn-sm md:btn-md md:w-auto'
              onClick={() => handleCookieAcceptance('Accept Selected')}>
              Accept Selected
            </Button>
            <Button
              className='btn-base-100 btn-sm md:btn-md md:w-auto'
              onClick={() => handleCookieAcceptance('Accept All')}>
              Accept All
            </Button>
          </div>
        </div>
      </div>

      {!isCookieSet ? (
        <div
          className={`${showCookieIcon ? 'fixed bottom-16 right-4 z-30  gap-4  lg:bottom-5 lg:right-8' : 'hidden'} `}>
          <div
            className={`${isCookieClicked ? '' : 'tooltip tooltip-left tooltip-primary'} `}
            data-tip='Cookies preferences'>
            <Button
              className='custom-transition-a btn-link hidden border p-1 md:block'
              onClick={() => setIsCookieClicked(!isCookieClicked)}>
              {isCookieClicked ? (
                <FaCookieBite fontSize={36} className='text-primary' />
              ) : (
                <FaCookie fontSize={36} className='text-primary' />
              )}
            </Button>
          </div>
        </div>
      ) : null}
    </ProductionContainer>
  )
}

export default Cookie
