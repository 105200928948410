const getPlatform = () => {
  let platform = 'unknown'

  // 2022 way of detecting. Note : this userAgentData feature is available only in secure contexts (HTTPS)
  if (typeof navigator.userAgentData !== 'undefined' && navigator.userAgentData != null) {
    platform = navigator.userAgentData.platform
  }

  // Deprecated but still works for most of the browser
  else if (typeof navigator.platform !== 'undefined') {
    if (
      typeof navigator.userAgent !== 'undefined' &&
      /android/.test(navigator.userAgent.toLowerCase())
    ) {
      // android device’s navigator.platform is often set as 'linux', so let’s use userAgent for them
      platform = 'android'
    }

    platform = navigator.platform
  }

  return (platform || 'unknown').toLowerCase()
}

export default getPlatform
