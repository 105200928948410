import cn from 'clsx'
import Image from 'components/atoms/Image'
import ROUTES from 'consts/Routes'
import useIsMobile from 'hooks/useIsMobile'
import Link from 'next/link'
// import { useRouter } from 'next/router'
import React from 'react'
import { useSelector } from 'react-redux'

// import { setTitleBar } from 'store/reducers'

const logoTypeBlack = '/assets/icons/logo-type-black.svg'
const logoBlack = '/assets/svg/logo-black.svg'

const BackBar = () => {
  // const dispatch = useDispatch()

  const titleBar = useSelector(state => state?.pr?.ui?.titleBar)
  const theme = useSelector(state => state?.pr?.ui?.theme)
  const accessToken = useSelector(state => state?.pr?.user?.auth?.stsTokenManager?.accessToken)

  const isMobile = useIsMobile('mobile')

  const imageSrc = isMobile || titleBar?.title ? logoBlack : logoTypeBlack

  // const isDrawerOpen = useSelector(state => state?.pr?.ui?.isDrawerOpen)

  // const handleBackPress = () => {
  //   dispatch(setTitleBar({}))
  //   router.push(titleBar?.backlink)
  // }

  return (
    <div
      className={cn(
        'custom-transition-a col-span-1 flex cursor-pointer items-center justify-between gap-2 rounded-xl lg:col-span-6',
        'lg:space-x-4'
      )}>
      {/* {titleBar?.backlink && (
        <BsArrowLeftCircle
          size={24}
          onClick={handleBackPress}
          className='rounded-full text-base-content hover:text-secondary lg:hidden'
        />
      )} */}

      <Link className='cursor-pointer' href={accessToken ? ROUTES.USER_DASHBOARD : ROUTES.ROOT}>
        <Image
          // src={logoTypeBlack}
          src={imageSrc}
          alt='Icon White'
          width={24}
          height={24}
          className={cn(
            'h-6',
            titleBar?.title ? 'w-8' : 'w-28',
            theme === 'dark' ? 'invert filter' : 'invert-0'
          )}
        />
      </Link>

      <h2 className='hidden flex-1 items-center gap-2 text-center text-lg font-bold md:flex md:gap-4'>
        {/* {titleBar?.backlink && (
          <BsArrowLeftCircle
            size={24}
            onClick={handleBackPress}
            className='hidden rounded-full text-base-content hover:text-secondary lg:block'
          />
        )} */}

        {titleBar?.title}
      </h2>
    </div>
  )
}

export default BackBar
