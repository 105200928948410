// useConfirmation.ts
import { useDispatch } from 'react-redux'
import { closeConfirmation, openConfirmation } from 'store/reducers'

const useConfirmation = () => {
  const dispatch = useDispatch()

  const showConfirmation = (
    title: string,
    message: string,
    onConfirm: () => void,
    onCancel: () => void = () => {}
  ) => {
    dispatch(
      openConfirmation({
        title,
        message,
        onConfirm,
        onCancel,
      })
    )
  }

  const closeConfirmationDialog = () => {
    dispatch(closeConfirmation())
  }

  return { showConfirmation, closeConfirmationDialog }
}

export default useConfirmation
