// Structured Data for Blogs.
// See https://developers.google.com/search/docs/appearance/structured-data/article#json-ld
// See https://schema.org/Event
import Head from 'next/head'
import React from 'react'
import { getSeoObjectForBlog } from 'utils/Seo'

const SeoBlog = ({ rawBlog }) => {
  if (!rawBlog) return null

  const blog = getSeoObjectForBlog(rawBlog)

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    headline: blog?.title,
    image: blog?.images,
    datePublished: blog?.datePublished,
    dateModified: blog?.dateModified,
    author: blog?.author,
  }

  return (
    <Head>
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
      />
    </Head>
  )
}

export default SeoBlog
