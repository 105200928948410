import cn from 'clsx'
import { getAvailableTickets } from 'utils/Events'
import { getSimplifiedPriceLocale } from 'utils/Pricing'

const TicketPrice = ({
  tickets,
  showPrice,
  isMinimal = false,
  customClasses = '',
  currency = 'GBP',
}) => {
  if (!showPrice || !tickets?.length) return null

  const availableTickets = getAvailableTickets(tickets)
  const priceRange = availableTickets?.map(t => t?.price)
  const lowestPrice = Math.min(...priceRange)
  const highestPrice = Math.max(...priceRange)

  // Derived
  const freeEvent = lowestPrice === 0 && highestPrice === 0
  const paidEventWithRange = priceRange?.length > 1 && lowestPrice !== highestPrice

  const className = cn(
    'flex items-center justify-between rounded-lg',
    isMinimal ? 'py-0' : 'py-1',
    customClasses
    // 'border border-base-200 bg-secondary text-base-100 px-2 '
  )

  if (freeEvent) {
    return <p className={className}>Free Entry</p>
  } else if (paidEventWithRange) {
    return (
      <p className={className}>
        {/* {tickets.length > 1 && 'Starting'} {getPriceLocaleOnEventCards(lowestPrice)} */}
        {getSimplifiedPriceLocale(lowestPrice, currency, true)}
      </p>
    )
  }

  // Default
  return (
    <p className={className}>
      {/* From {getPriceLocaleOnEventCards(lowestPrice)} - To {getPriceLocaleOnEventCards(highestPrice)} */}
      {getSimplifiedPriceLocale(lowestPrice, currency, true)}
    </p>
  )
}

export default TicketPrice
