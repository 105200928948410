import Styles from './SkipToMainContent.module.scss'

const SkipToMainContent = () => {
  return (
    <a className={Styles.skipToContentLink} href='#main-content'>
      Skip to main content
    </a>
  )
}

// Export Pure Component
export default SkipToMainContent
