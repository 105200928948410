import { TICKET_PROCESSING_FIXED, TICKET_PROCESSING_PERCENTAGE } from 'consts'

import { getCurrencySymbol } from './Currencies'

export const customRound = (number, precision = 2) => {
  if (typeof number === 'string' && !isNaN(number)) {
    number = +number // Convert numeric string to number
  }

  return +Number(number || 0).toFixed(precision)
  // return roundedNumber.toString()
}

// Pence to Pounds Conversion
export const ptp = (pence, prefix = true) => {
  return prefix ? '£' + customRound(pence / 100) : customRound(pence / 100)
}

export const poundToPence = pounds => {
  // no floating point shenanigans
  return Math.round(pounds * 100)
}

// Use this when amount includes fees and you need to add fees to it
// return x = 6% + 0.2
export const getProcessingFeesOn = amount => {
  if (amount === 0) return 0 // No fees for free tickets

  const calculatedFees = amount * (TICKET_PROCESSING_PERCENTAGE / 100) + TICKET_PROCESSING_FIXED
  return customRound(calculatedFees)
}

/*
 * (OLD) net = (gross - 0.2) * (100 / 106)
 * (CURRENT) net = gross - 0.06 * gross - 0.2
 * The Reason why newer logic is use to make the fee consistent,
 * irrespective of whether the buyer pays the fees or not.
 */
export const getNetTicketPrice = grossAmt => {
  if (grossAmt === 0) return 0 // No fees for free tickets

  const netTicketPrice = grossAmt - getProcessingFeesOn(grossAmt)
  return customRound(netTicketPrice)
}

// return x = grossAmt - netAmt
export const getProcessingFeesOnGrossTicketPrice = grossAmt => {
  if (grossAmt === 0) return 0 // No fees for free tickets

  const ourFees = grossAmt - getNetTicketPrice(grossAmt)
  return customRound(ourFees)
}

export const getGrossTicketPrice = amount => {
  if (amount === 0) return 0 // No fees for free tickets

  const buyersTotal = amount + getProcessingFeesOn(amount)
  return customRound(buyersTotal)
}

// ---------------------- TICKET PRICING ----------------------
export const getGrossRevenue = (orders, eventId) => {
  const grossRevenue =
    orders
      ?.filter(i => i.eventId === eventId)
      ?.reduce((total, i) => total + i?.paymentIntent?.amount, 0) || 0

  const sanitizedGrossRevenue = parseFloat((grossRevenue / 100).toFixed(2)) || 0

  return sanitizedGrossRevenue
}

export const getTotalProcessingFees = (orders, eventId) => {
  const netRevenue =
    orders
      ?.filter(i => i.eventId === eventId)
      ?.reduce((total, i) => total + parseFloat(i?.processingFees || 0), 0) || 0

  const sanitizedNetRevenue = parseFloat(netRevenue.toFixed(2)) || 0

  return sanitizedNetRevenue
}

export const roundToBase1000 = number => {
  if (number < 1000) {
    return number.toString()
  }

  const suffixes = ['', 'k', 'M', 'B', 'T']
  const i = Math.floor(Math.log10(number) / 3)
  const scaled = number / Math.pow(1000, i)

  return `${scaled.toFixed(scaled % 1 === 0 ? 0 : 1)}${suffixes[i]}`
}

export const getSimplifiedPriceLocale = (price, currencyCode = 'GBP', labelFree = false) => {
  if (labelFree && !price) return 'Free'

  const convertedPrice = parseFloat(price)
  const roundedPrice = customRound(convertedPrice)
  const smartlyRoundedPrice = roundedPrice % 1 === 0 ? roundedPrice : roundedPrice.toFixed(2)

  return `${getCurrencySymbol(currencyCode)}${smartlyRoundedPrice}`
}
