import ReusableGrid from 'components/containers/ReusableGrid'
import Icons from 'consts/Icons'
import React, { useEffect, useState } from 'react'

const eventTicketingTrivia = [
  'The first online ticket was sold in 1994 by a company called First Virtual for a Sting concert.',
  "By 2023, it's estimated that over 50% of event tickets were purchased via mobile devices.",
  "The term 'scalping' for reselling tickets comes from the late 1800s, when people would resell train tickets and other event tickets at a markup.",
  'The most expensive Super Bowl ticket sold on record was over $27,500 for Super Bowl LIV in 2020.',
  'The Donauinselfest in Vienna, Austria, holds the record for the largest music festival, attracting around 3 million attendees each year.',
  'The first printed tickets were introduced by a British company called W S Fry in the 1880s.',
  'Founded in 2000, StubHub revolutionized the secondary ticket market, making it a multi-billion-dollar industry.',
  'Blockchain technology is increasingly being used in ticketing to combat fraud and ensure ticket authenticity.',
  'In 2019, the global live music industry generated over $28 billion in revenue from ticket sales alone.',
  'Dynamic pricing in ticketing, similar to airline pricing, allows prices to fluctuate based on demand and is used by major sports teams and concert promoters.',
  'Ticketmaster, founded in 1976, merged with Live Nation in 2010, creating a ticketing giant that controls about 80% of the U.S. primary ticketing market.',
  "The most expensive concert ticket sold was for Led Zeppelin's reunion show in 2007 at London’s O2 Arena, with some tickets reselling for over $14,000.",
  'E-tickets now account for nearly 70% of all tickets sold globally, making paper tickets almost obsolete.',
  'The first known sports ticket was issued for a baseball game in 1849 between the Knickerbockers and the New York Nine.',
  'VR tickets are gaining popularity, allowing fans to experience live events from their homes with immersive technology.',
  'The concept of early bird discounts originated in the 1960s to encourage ticket sales well in advance of an event.',
  "It's estimated that over 5 million fake event tickets are sold annually, leading to billions in losses.",
  "Beyoncé's 2016 Formation World Tour had tickets selling out in seconds, leading to the term 'Beyoncé Effect' in the ticketing industry.",
  'The largest single event ticket sale was the 2012 London Olympics, with over 10 million tickets sold.',
  'Ticket insurance has become increasingly popular, covering everything from illness to transportation issues, and is offered by most major ticketing platforms.',

  // UK-specific trivia
  'The Glastonbury Festival in Somerset is the largest greenfield festival in the world, with over 200,000 attendees each year.',
  'The Royal Albert Hall in London hosts over 390 events a year, including the famous Proms concert series.',
  'In 2018, the Edinburgh Fringe Festival sold over 2.8 million tickets, making it the world’s largest arts festival.',
  'The first known ticketed sporting event in the UK was a horse race at Newmarket in 1634.',
  'Wembley Stadium, the largest stadium in the UK, has a capacity of 90,000 and regularly hosts major concerts and events.',
  'The West End in London is the largest theatre district in the world, with over 15 million tickets sold annually.',
  'The O2 Arena in London is the world’s busiest music venue by ticket sales, hosting over 200 events annually.',
  'In 2012, London hosted the first Olympic Games to sell tickets entirely online.',
  'The Notting Hill Carnival is Europe’s largest street festival, with over 2 million attendees each year.',
  "The UK's Ticket Touting (Prohibition) Act of 1924 was one of the earliest attempts to regulate ticket resale, long before the internet era.",

  // Grassroots Venues Scene trivia
  'Grassroots music venues in the UK are often where many famous bands like Oasis, Arctic Monkeys, and Radiohead first got their start.',
  'Over 35% of grassroots music venues in the UK have closed in the last decade due to rising costs and gentrification.',
  'The Music Venue Trust was established in 2014 to protect and support grassroots music venues across the UK.',
  "Grassroots venues play a crucial role in the UK's music ecosystem, hosting over 91,000 events annually.",
  'In 2019, the UK government introduced a £1.5 million fund to support grassroots music venues.',
  'The 100 Club in London, a historic grassroots venue, has hosted performances by bands like The Rolling Stones and The Sex Pistols.',
  "The UK's grassroots music venues collectively generate over £360 million annually for the economy.",
  'Many grassroots venues are volunteer-run, relying on the passion and dedication of local music fans.',
  'The Leadmill in Sheffield, a grassroots venue, has been a launching pad for acts like Pulp and The Arctic Monkeys.',
  'Grassroots venues are vital for artist development, with many venues providing opportunities for unsigned and emerging talent to perform.',
]

function getRandomMessage() {
  const index = Math.floor(Math.random() * eventTicketingTrivia.length)
  return eventTicketingTrivia[index]
}

const Trivia = () => {
  const [message, setMessage] = useState(null)

  useEffect(() => {
    const interval = setInterval(() => {
      setMessage(getRandomMessage())
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  return (
    <ReusableGrid>
      <div className='custom-transition-a card col-span-full flex flex-col gap-2 '>
        <div className='flex flex-row items-center gap-2'>
          <Icons.IBulb size={36} className='animate-pulse' />
          <span className='text-xl font-medium'>Did You Know?</span>
        </div>
        <span className='animate-fade-up text-base'>{message}</span>
      </div>
    </ReusableGrid>
  )
}

export default Trivia
