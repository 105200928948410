import cn from 'clsx'
import React from 'react'

const Heading = ({
  className = '',
  heroHeading = false,
  title = '',
  preTitle = '',
  postTitle = '',
  tooltipLabel = '',
  tooltipValue = '',
  ...props
}) => {
  return (
    <div className={cn(className, 'animate-fade-up')} {...props}>
      {preTitle && <h3 className='mb-2 text-base font-normal md:text-lg'>{preTitle}</h3>}

      <div className='flex h-fit flex-row items-end gap-5'>
        {heroHeading ? (
          <h1 className='text-4xl font-semibold'>{title}</h1>
        ) : (
          <h2 className='text-2xl font-bold md:text-4xl'>{title}</h2>
        )}

        {tooltipValue && (
          <div className='tooltip h-fit font-bold text-secondary underline' data-tip={tooltipValue}>
            {tooltipLabel ?? `ℹ`}
          </div>
        )}
      </div>

      {postTitle && <h4 className='mt-4'>{postTitle}</h4>}

      {/* <CTAButton text='All Events' onClick={() => router.push('/u/events')} /> */}
    </div>
  )
}

export default Heading
