import { EventTypeData } from 'components/organisms/Events/EventTypeData'
import {
  FETCH_LIMIT,
  UPSELL_RECOMMENDATION_LIMIT,
  UPSELL_RECOMMENDATION_PER_TYPE_LIMIT,
} from 'consts/Firebase'
import MSG from 'consts/Messages'
import {
  Timestamp,
  collection,
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { toast } from 'react-toastify'
import { db } from 'services/FirebaseClient'
import { getCutOffTimestamp } from 'utils/Timestamp'

// #############################################################################
// #                                CREATE                                     #
// #############################################################################

// #############################################################################
// #                                READ                                       #
// #############################################################################
export const readPublicEvents = async (callback = () => {}) => {
  const cutOffLastThreeDays = getCutOffTimestamp('past', 3)
  const ref = query(
    collection(db, 'events'),
    where('date', '>=', cutOffLastThreeDays),
    where('isPublished', '==', true),
    where('isPrivate', '==', false),
    where('isArchived', '==', false),
    where('isCancelled', '==', false),
    orderBy('date', 'asc'),
    limit(FETCH_LIMIT)
  )

  try {
    const snapshot = await getDocs(ref)
    const events = snapshot.docs.map(doc => doc.data())
    callback(events)
    return events
  } catch (error) {
    toast.error(MSG.ERROR.OPERATION_FAILED, { toastId: 'duplication-error' })
    console.error('Error @fetchAllPublicEvents', error)
  }
}

export const readEventRecommendations = async (userId, hostId, eventType) => {
  console.log('📌 @readEventRecommendations', userId, hostId, eventType)
  if (!userId || !eventType || !hostId) return []

  // Fetch user's previously ordered events to avoid recommending them the same event again
  // Also only taking future event orders in account since we are only recommending future events
  const userOrdersRef = query(collection(db, 'orders'), where('user.userId', '==', userId))

  let userOrderedEventIds = []
  try {
    const ordersSnapshot = await getDocs(userOrdersRef)
    userOrderedEventIds = Array.from(
      new Set(ordersSnapshot.docs.map(doc => doc.data()).map(order => order?.event?.eventId))
    )
  } catch (error) {
    toast.error(MSG.ERROR.BUYER.RECOMMENDATIONS_FETCH_FAILED, {
      toastId: 'recommendation-fetching-failed',
    })
    console.error('Error fetching user orders', error)
    return []
  }

  // Query for events by type (not limited by host)
  const eventsByTypeRef = query(
    collection(db, 'events'),
    where('eventType', '==', eventType),
    where('date', '>=', Timestamp.now()),
    where('isPublished', '==', true),
    where('isPrivate', '==', false),
    where('isArchived', '==', false),
    where('isCancelled', '==', false),
    limit(UPSELL_RECOMMENDATION_PER_TYPE_LIMIT)
  )

  // Query for events by host (not limited by event type)
  const eventsByHostRef = query(
    collection(db, 'events'),
    where('hostId', '==', hostId),
    where('date', '>=', Timestamp.now()),
    where('isPublished', '==', true),
    where('isPrivate', '==', false),
    where('isArchived', '==', false),
    where('isCancelled', '==', false),
    limit(UPSELL_RECOMMENDATION_PER_TYPE_LIMIT)
  )

  try {
    const [typeSnapshot, hostSnapshot] = await Promise.all([
      getDocs(eventsByTypeRef),
      getDocs(eventsByHostRef),
    ])

    const eventsByType = typeSnapshot.docs.map(doc => doc.data())
    const eventsByHost = hostSnapshot.docs.map(doc => doc.data())

    // Combine events by type and by host, and filter out events the user has already ordered
    let combinedEvents = [...eventsByType, ...eventsByHost].filter(
      event => !userOrderedEventIds.includes(event?.eventId)
    )

    // Remove duplicate events
    combinedEvents = Array.from(new Set(combinedEvents.map(event => event.eventId))).map(id =>
      combinedEvents.find(event => event.eventId === id)
    )

    // If we don't have enough events, fetch additional events
    if (combinedEvents.length < 8) {
      const additionalEventsRef = query(
        collection(db, 'events'),
        where('date', '>=', Timestamp.now()),
        where('isPublished', '==', true),
        where('isPrivate', '==', false),
        where('isArchived', '==', false),
        where('isCancelled', '==', false),
        orderBy('date', 'asc'),
        limit(FETCH_LIMIT)
      )

      const additionalEventsSnapshot = await getDocs(additionalEventsRef)

      // Filter events that the user has already bought and sort according to the popularity
      const additionalEvents = additionalEventsSnapshot.docs
        .map(doc => doc.data())
        .filter(event => !userOrderedEventIds.includes(event?.eventId))
        .sort((a, b) => {
          return b?.summary?.ticketCount - a?.summary?.ticketCount
        })

      // Merge additional events with combined events
      combinedEvents = [...combinedEvents, ...additionalEvents]

      // Remove duplicate events from the list once again
      combinedEvents = Array.from(new Set(combinedEvents.map(event => event.eventId))).map(id =>
        combinedEvents.find(event => event.eventId === id)
      )
    }

    const recommendedEvents = combinedEvents.slice(0, UPSELL_RECOMMENDATION_LIMIT)

    return recommendedEvents
  } catch (error) {
    toast.error(MSG.ERROR.BUYER.RECOMMENDATIONS_FETCH_FAILED, {
      toastId: 'recommendation-fetching-failed',
    })
    console.error('Error @readEventRecommendations', error)
    return []
  }
}

export const readPublicEventsByType = async eventType => {
  console.log('📌 @readPublicEventsByType', eventType)
  if (!eventType) return []

  const ref = query(
    collection(db, 'events'),
    where('eventType', '==', eventType),
    where('date', '>=', Timestamp.now()),
    where('isPublished', '==', true),
    where('isPrivate', '==', false),
    where('isArchived', '==', false),
    where('isCancelled', '==', false),
    limit(FETCH_LIMIT)
  )

  try {
    const snapshot = await getDocs(ref)
    const events = snapshot.docs.map(doc => doc.data())
    return events
  } catch (error) {
    toast.error(MSG.ERROR.OPERATION_FAILED, { toastId: 'read-error' })
    console.error('Error @readPublicEventsByType', error)
    return []
  }
}

export const readEventCountByType = async eventType => {
  try {
    const now = new Date()
    const startOfDay = new Date(now.setHours(0, 0, 0, 0))
    const startOfDayTimestamp = Timestamp.fromDate(startOfDay)

    const ref = query(
      collection(db, 'events'),
      where('eventType', '==', eventType),
      where('date', '>=', startOfDayTimestamp),
      where('isPublished', '==', true),
      where('isPrivate', '==', false),
      where('isArchived', '==', false),
      where('isCancelled', '==', false)
    )

    const snapshot = await getCountFromServer(ref)
    return snapshot.data().count
  } catch (error) {
    console.error('Error fetching event count:', error)
    return 0
  }
}

export const readAllEventCategoriesCount = async () => {
  try {
    const counts = await Promise.all(
      EventTypeData.map(async category => {
        const count = await readEventCountByType(category.type)
        return { type: category.type, count }
      })
    )

    const countsMap = counts.reduce((acc, { type, count }) => {
      acc[type] = count
      return acc
    }, {})

    return countsMap
  } catch (error) {
    console.error('Error fetching event counts:', error)
    throw error
  }
}

export const readRecentlyPublishedPublicEvents = async (fetchLimit = FETCH_LIMIT) => {
  const cutOffLastThreeDays = getCutOffTimestamp('past', 90)

  const ref = query(
    collection(db, 'events'),
    where('lastPublishedDate', '>=', cutOffLastThreeDays),
    where('isPublished', '==', true),
    where('isPrivate', '==', false),
    where('isArchived', '==', false),
    where('isCancelled', '==', false),
    orderBy('lastPublishedDate', 'desc'),
    limit(fetchLimit)
  )

  try {
    const snapshot = await getDocs(ref)
    const events = snapshot.docs.map(doc => doc.data())

    return events
  } catch (error) {
    toast.error(MSG.ERROR.OPERATION_FAILED, { toastId: 'duplication-error' })
    console.error('Error @readRecentlyPublishedPublicEvents', error)

    return []
  }
}

export const readTrendingPublicEvents = async () => {
  const cutOffLastThreeDays = getCutOffTimestamp('past', 0)

  const ref = query(
    collection(db, 'events'),
    where('date', '>=', cutOffLastThreeDays),
    where('summary.projectedGrandTotal', '>', 0), // Paid Events Only
    where('isPublished', '==', true),
    where('isPrivate', '==', false),
    where('isArchived', '==', false),
    where('isCancelled', '==', false),
    where('summary.occupancyPercent', '<', 100), // Not Sold Out
    orderBy('summary.occupancyPercent', 'desc'),
    limit(FETCH_LIMIT)
  )

  try {
    const snapshot = await getDocs(ref)
    const events = snapshot.docs.map(doc => doc.data())

    return events
  } catch (error) {
    toast.error(MSG.ERROR.OPERATION_FAILED, { toastId: '@readTrendingPublicEvents' })
    console.error('Error @readTrendingPublicEvents', error)
  }
}

// #############################################################################
// #                                UPDATE                                     #
// #############################################################################

// #############################################################################
// #                                DELETE                                     #
// #############################################################################
