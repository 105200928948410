import React from 'react'

const ProductionContainer = ({ children }) => {
  // -----------------------------------
  // STICT FAILURE CHECK FIRST
  // -----------------------------------
  if (process.env.NEXT_PUBLIC_APP_ENV !== 'production') return null

  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  // TODO - Decide how to handle beta env
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  // -----------------------------------
  // UI (JSX) RENDERING
  // -----------------------------------
  return <>{children}</>
}

export default ProductionContainer
