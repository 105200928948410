import { BsPersonCircle } from '@react-icons/all-files/bs/BsPersonCircle'
import { IoChevronDownOutline } from '@react-icons/all-files/io5/IoChevronDownOutline'
import cn from 'clsx'
import Image from 'components/atoms/Image'
import VerifiedBadge from 'components/organisms/Badge/VerifiedBadge'
import Icons from 'consts/Icons'
import ROUTES from 'consts/Routes'
import { googleMeetScheduleLink } from 'consts/Urls'
import useLogOut from 'hooks/useLogOut'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { useSelector } from 'react-redux'
import { getBaseUrlFor } from 'utils/Url'

import ThemeToggler from './ThemeToggler'

const ProfileMenu = () => {
  const { logOutHandler } = useLogOut()
  const accessToken = useSelector(state => state?.pr?.user?.auth?.stsTokenManager?.accessToken)
  const profileImage = useSelector(state => state?.pr?.user?.info?.profileImage)
  const user = useSelector(state => state?.pr?.user?.info)
  const name = user?.hostTitle || `${user?.firstName} ${user?.lastName}` || user?.email
  const theme = useSelector(state => state?.pr?.ui?.theme)
  const router = useRouter()

  const isHost = user?.userType === 'host'

  const PROFILE_MENU = {
    UNAUTH: [
      {
        title: 'Separator',
      },
      {
        title: 'Log In',
        icon: <Icons.LogIn size={20} />,
        path: ROUTES.LOG_IN,
      },
      {
        title: 'Sign Up Free',
        icon: <Icons.PenTool size={20} />,
        path: ROUTES.SIGN_UP,
      },
      {
        title: 'Separator',
      },
      {
        title: 'Help',
        icon: <Icons.Help size={20} />,
        path: ROUTES.HELP,
      },
      {
        title: 'Schedule a Meeting',
        icon: <Icons.Meet size={20} />,
        onClick: () => window.open(googleMeetScheduleLink, '_blank'),
      },
      {
        title: 'Theme Toggler',
      },
    ],
    AUTH_USER: [
      // {
      //   title: 'Separator',
      // },
      {
        title: 'My Tickets',
        icon: <Icons.TicketIcon size={20} />,
        path: ROUTES.USER_TICKETS,
      },
      {
        title: 'Feed',
        icon: <Icons.IOutlineFeed size={20} />,
        path: ROUTES.USER_FEED,
      },
      {
        title: 'Settings',
        icon: <Icons.Settings size={20} />,
        path: ROUTES.USER_SETTINGS,
      },
      {
        title: 'Theme Toggler',
      },
      {
        title: 'Separator',
      },
      // {
      //   title: 'Switch to Selling',
      //   icon: <Icons.SwtichToSellingOrHosting size={20} />,
      //   path: ROUTES.ONBOARDING,
      // },
      {
        title: isHost ? 'Partners Studio' : 'Become a Partner',
        icon: <Icons.ExternalLink size={20} />,
        onClick: () => window.open(getBaseUrlFor('partners'), '_blank'),
      },
      {
        title: 'Book a Meeting',
        icon: <Icons.ICalendar size={20} />,
        onClick: () => window.open(googleMeetScheduleLink, '_blank'),
      },
      ...(user?.userType === 'host'
        ? [
            {
              title: 'My Signage',
              icon: <Icons.RiLayout6Fill size={20} />,
              path: ROUTES.SIGNAGE.replace(':hostId', user?.id),
            },
          ]
        : []),
      {
        title: 'Separator',
      },
      {
        title: 'Sign out',
        icon: <Icons.LogOut size={20} />,
        onClick: logOutHandler,
      },
    ],
  }

  let MENU

  if (accessToken) {
    MENU = PROFILE_MENU.AUTH_USER
  } else {
    MENU = PROFILE_MENU.UNAUTH
  }

  return (
    <div className='dropdown dropdown-end font-thin ' id='profile-menu'>
      <div role='button' tabIndex={0} className=' flex cursor-pointer'>
        {accessToken ? (
          <div className='avatar'>
            {/* <IoChevronDownOutline
              className={` absolute bottom-0 right-0 z-10 rounded-3xl ${!theme === 'light' ? 'bg-white text-black' : ' bg-slate-900 text-white'}`}
              size={14}
            /> */}
            <div className='w-10 rounded-full'>
              <Image src={profileImage} alt='Profile' width={20} height={20} />
            </div>
          </div>
        ) : (
          <div>
            <BsPersonCircle size={27} />
            <IoChevronDownOutline
              className={` absolute -bottom-1 right-0 z-10 rounded-3xl ${theme === 'light' ? 'bg-white text-black' : ' bg-slate-900 text-white'}`}
              size={12}
            />
          </div>
        )}
      </div>

      <ul
        tabIndex={0}
        className='menu dropdown-content mt-4 min-w-60 gap-2 rounded-box bg-base-200 py-4 shadow'>
        {accessToken ? (
          <li
            onClick={() => {
              router.push(
                isHost ? ROUTES.BOOKING_WIDGET.replace(':hostId', user?.id) : ROUTES.USER_PROFILE
              )
            }}>
            <div className='flex cursor-pointer items-center justify-start gap-3'>
              <div className='avatar'>
                <div className='w-12 rounded-full'>
                  <Image src={profileImage} alt='Profile' width={20} height={20} />
                </div>
              </div>
              <div id='name-badge' className=' flex items-center gap-2'>
                {name && <span className='font-semibold md:flex'>{name}</span>}
                {user?.isVerified && isHost && <VerifiedBadge />}
              </div>
            </div>
          </li>
        ) : (
          <h3 className='flex flex-col items-center justify-center font-semibold'>Your Account</h3>
        )}

        {MENU?.map((item, index) => {
          if (item.title === 'Separator')
            return <li key={index} className={cn('border-r border-base-300', item?.className)} />

          if (item.title === 'Theme Toggler')
            return (
              <li key='theme-toggler'>
                <ThemeToggler />
              </li>
            )

          return (
            <li
              key={item.title}
              className={item?.className}
              onClick={() => {
                document.getElementById('profile-menu').removeAttribute('open')
                if (item?.onClick) {
                  item.onClick()
                }
              }}>
              {item?.title === 'Sign out' ? (
                <div className={cn('flex h-full w-full items-center justify-center text-center')}>
                  {item?.icon}
                  <span className='font-semibold'>{item?.title}</span>
                </div>
              ) : (
                <Link
                  href={item?.path ?? '#'}
                  className={cn('flex h-full w-full items-center text-center')}>
                  {item?.icon}
                  <span>{item?.title}</span>
                </Link>
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default ProfileMenu
