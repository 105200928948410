import useConfirmation from 'hooks/useConfirmation'
import React from 'react'
import { useSelector } from 'react-redux'

interface RootConfirmationState {
  pr: {
    confirmation: {
      isOpen: boolean
      title: string
      message: string
      onConfirm: () => void
      onCancel: () => void
    }
  }
}

const ConfirmationDialog = () => {
  const globalConfirmation =
    useSelector((state: RootConfirmationState) => state?.pr?.confirmation) || null

  const { closeConfirmationDialog } = useConfirmation()

  if (!globalConfirmation?.isOpen) return null

  return (
    <>
      <input
        type='checkbox'
        defaultChecked
        id='globalConfirmationCheckBox'
        className='modal-toggle'
      />
      <div className='modal z-[1600]' role='dialog'>
        <div className='modal-box max-w-md'>
          <h3 className='text-lg font-bold'>{globalConfirmation?.title || 'Confirmation'}</h3>
          <p className='py-2 font-thin'>
            {globalConfirmation?.message || 'Confirm or cancel your changes!'}
          </p>
          <div className='modal-action mt-2'>
            <button
              className='btn btn-primary btn-md min-w-20'
              onClick={() => {
                globalConfirmation?.onConfirm()
                closeConfirmationDialog()
              }}>
              Yes
            </button>

            <button
              className='btn btn-secondary btn-md min-w-20'
              onClick={() => {
                globalConfirmation?.onCancel()
                closeConfirmationDialog()
              }}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ConfirmationDialog
