import type { AnyAction, PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

interface LoadingState {
  isLoading: boolean
  message: string
}

const initialState = {
  isLoading: false,
  message: '',
}

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<LoadingState>) => ({
      ...state,
      isLoading: action.payload.isLoading,
      message: action.payload.message,
    }),

    resetLoader: () => initialState,
  },
  // extraReducers: {
  //   [HYDRATE]: (state, action) => ({
  //     ...state,
  //     ...action.payload,
  //   }),
  // },

  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      return {
        ...state,
        ...action.payload,
      }
    })
  },
})

export const { setLoading, resetLoader } = loadingSlice.actions

export default loadingSlice.reducer
