import MSG from 'consts/Messages'
import { collection, getDocs, onSnapshot, query, where } from 'firebase/firestore'
import { toast } from 'react-toastify'
import { db } from 'services/FirebaseClient'

// #############################################################################
// #                                CREATE                                     #
// #############################################################################

// #############################################################################
// #                                READ                                       #
// #############################################################################
export const readOrderById = async orderId => {
  if (!orderId) return

  const q = query(
    collection(db, 'orders'),
    where('id', '==', orderId)
    // where('isCancelled', '==', false)
  )

  try {
    const res = await getDocs(q)
    const order = res.docs.map(doc => doc.data())[0] || {}

    return order
  } catch (error) {
    toast.error(MSG.ERROR.BUYER.FETCH_ORDER_DETAILS, { toastId: 'get-order-failed' })
    console.error('🚩 @readOrderById', err.message)
  }
}

export const readOrderByIdwRTU = async (orderId, callback) => {
  if (!orderId) return

  const q = query(collection(db, 'orders'), where('id', '==', orderId))

  try {
    onSnapshot(
      q,
      snapshot => {
        if (snapshot.empty) {
          callback(null)
          return
        }

        const order = snapshot.docs.map(doc => doc.data())[0] || null
        callback(order)
      },
      error => {
        toast.error(MSG.ERROR.BUYER.FETCH_ORDER_DETAILS, {
          toastId: 'get-order-failed',
        })
        console.error('🚩 @readOrderByIdwRTU', error.message)
        callback(null)
      }
    )
  } catch (error) {
    toast.error(MSG.ERROR.BUYER.FETCH_ORDER_DETAILS, {
      toastId: 'get-order-failed',
    })
    console.error('🚩 @readOrderByIdwRTU', error.message)
    callback(null)
  }
}

// Check if the user has checked-in in an order or a ticket
export const readCheckInStatusById = async orderId => {
  if (!orderId) return
  try {
    const q = query(collection(db, 'orders'), where('id', '==', orderId))
    const querySnapshot = await getDocs(q)
    return querySnapshot.docs.map(doc => doc.data().checkedIn)
  } catch (error) {
    console.error('Error @readCheckInStatusById', error)
    toast.error(error.message)
  }
}

// #############################################################################
// #                                UPDATE                                     #
// #############################################################################

// #############################################################################
// #                                DELETE                                     #
// #############################################################################
