import cn from 'clsx'
import Container from 'components/containers/Container'
import GridContainer from 'components/containers/GridContainer'
import SectionContainer from 'components/containers/SectionContainer'
import BackBar from 'components/molecules/BackBar/BackBar'
import ProfileMenu from 'components/molecules/ProfileMenu'
import SearchInput from 'components/molecules/SearchInput'
import UnVerifiedBanner from 'components/molecules/UnVerifiedBanner'
import EventCard from 'components/organisms/Events/EventCard'
import { HEADER } from 'consts'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const Header = () => {
  const events = useSelector(state => state?.pr?.events?.allEvents) || []

  return (
    <SectionContainer
      id='header'
      className={cn(
        'custom-transition-b fixed top-0 z-40 w-full bg-base-100/50 pt-0 text-primary backdrop-blur-lg'
      )}>
      <Container>
        <GridContainer>
          <div className='col-span-full flex items-center justify-between gap-2 py-2'>
            <BackBar />

            <SearchInput
              data={events}
              searchKeys={[
                'name',
                'description',
                'host.firstName',
                'host.lastName',
                'host.hostTitle',
                'venue.name',
                'eventType',
              ]}
              placeholder='Search Events'
              renderItem={event => <EventCard key={event.eventId} event={event} isMinimal />}
            />

            <div className='flex items-center justify-center space-x-4'>
              <HeaderNav />
              <ProfileMenu />
            </div>
          </div>
        </GridContainer>
      </Container>
      <UnVerifiedBanner />
    </SectionContainer>
  )
}

const HeaderNav = () => {
  const accessToken = useSelector(state => state?.pr?.user?.auth?.stsTokenManager?.accessToken)
  const [links, setLinks] = useState(HEADER.NAVIGATION)

  useEffect(() => {
    if (accessToken) {
      setLinks(HEADER.APP_USER_NAVIGATION)
    } else {
      setLinks(HEADER.NAVIGATION)
    }
  }, [accessToken])

  // Handle internal and external links. Internal by default
  const Linkk = ({ item }) => {
    if (item?.external) {
      return (
        <a
          href={item.path}
          target='_blank'
          rel='noreferrer'
          className={cn(
            'flex h-full w-full flex-row items-center text-center',
            item?.icon && 'gap-2'
          )}>
          {item.icon}
          {item.title}
        </a>
      )
    }

    return (
      <Link
        href={item.path || '#'}
        className={cn(
          'flex h-full w-full flex-row items-center text-center',
          item?.icon && 'gap-2'
        )}>
        {item.icon}
        {item.title}
      </Link>
    )
  }

  return (
    <ul className='ml-4 flex items-center justify-center space-x-4'>
      {links.map((item, index) => {
        if (item.title === 'Separator')
          return (
            <li
              key={item.title + index}
              className={cn('h-6 border-r border-base-300', item?.className)}
            />
          )

        return (
          <li key={item.path} className={item?.className}>
            {item?.subMenu?.length ? (
              <div className='dropdown dropdown-end'>
                <div tabIndex='0' role='button' className='btn btn-sm m-1'>
                  {item.icon}
                  {item.title}
                </div>
                <ul
                  tabIndex='0'
                  className='menu dropdown-content z-[1] w-52 rounded-box bg-base-100 p-2 shadow'>
                  {item.subMenu.map((subItem, index) => (
                    <li key={subItem.path + index}>
                      {/* <Link href={subItem.path ||"#"} class='flex items-center'>
                        {subItem.title}
                      </Link> */}
                      <Linkk item={subItem} />
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <Linkk item={item} />
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default Header
