import { collection, onSnapshot } from 'firebase/firestore'
import { toast } from 'react-toastify'
import { db } from 'services/FirebaseClient'

export const getLatestAlert = async updateCallback => {
  try {
    const collectionRef = collection(db, 'feature-flags')
    onSnapshot(collectionRef, snapshot => {
      if (!snapshot.empty) {
        const alert = snapshot.docs[0].data()
        updateCallback(alert)
      }
    })
  } catch (err) {
    console.log('Error @getLatestAlert', err)
    toast.error(err.message)
  }
}
