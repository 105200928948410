import MainContainer from 'components/containers/MainContainer'
import Footer from 'components/organisms/nav/Footer'
import Header from 'components/organisms/nav/Header'
import { ROUTES } from 'consts'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

const DefaultLayout = ({ children }) => {
  const router = useRouter()
  const accessToken = useSelector(s => s?.pr?.user?.auth?.stsTokenManager?.accessToken)

  useEffect(() => {
    if (accessToken) router.push(ROUTES.USER_DASHBOARD)
  }, [accessToken])

  return (
    <>
      <Header />
      <MainContainer>{children}</MainContainer>
      <Footer />
    </>
  )
}

export default DefaultLayout
