const trimString = (s, TRIM_LENGTH = 16, dots = true) => {
  if (!s || typeof s !== 'string') return ''
  else if (s.length <= TRIM_LENGTH) return s

  // Default
  return s.substring(0, TRIM_LENGTH) + (dots ? '...' : '')
}

const stringToSlug = str => {
  if (!str || typeof str !== 'string') return ''

  return str
    .toLowerCase() // Convert the string to lowercase
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/[^a-z0-9\-]+/g, '') // Remove all non-alphanumeric characters except hyphens
    .replace(/\-\-+/g, '-') // Replace multiple hyphens with a single hyphen
    .replace(/^-+|-+$/g, '') // Remove leading and trailing hyphens
}

const checkIsStringAHtml = str => {
  if (!str || typeof str !== 'string') return false

  // Simple pattern concerning only the opening and closing tags
  const htmlPattern = /<\/?[a-z][\s\S]*>/i

  // Complex and more accurate pattern concerning all HTML tags
  // const htmlPattern = /<([a-z][a-z0-9]*)\b[^>]*>(.*?)<\/\1>|<([a-z][a-z0-9]*)\b[^>]*\/?>/i

  return htmlPattern.test(str)
}

const htmlToPlainString = html => {
  // Mapping of HTML entities to their corresponding characters
  const entityMap = {
    '&nbsp;': ' ',
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#39;': "'",
  }

  // Generate the regex pattern dynamically from the entityMap keys
  const entityPattern = new RegExp(Object.keys(entityMap).join('|'), 'g')
  // /&(?:nbsp|amp|lt|gt|quot|#39);/g

  return html
    ?.replace(/<[^>]*>/g, '') // Remove HTML tags
    ?.replace(entityPattern, entity => entityMap[entity] || entity) // Replace HTML entities
    ?.replace(/\s+/g, ' ') // Normalize whitespace
    ?.trim() // Trim leading and trailing whitespace
}

// Since slicing directly the HTML (as string) may break the tags, using a DOMParser here to parse the HTML and then slice the textContent is a safer approach
const sliceHtml = (html, maxLength) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  let textContent = doc.body.textContent || ''

  if (textContent.length > maxLength) {
    textContent = textContent.slice(0, maxLength) + '...'
  }

  return textContent
}

export { trimString, stringToSlug, checkIsStringAHtml, htmlToPlainString, sliceHtml }
