import { AiFillHome } from '@react-icons/all-files/ai/AiFillHome'
import cn from 'clsx'
import Icons from 'consts/Icons'
import ROUTES from 'consts/Routes'
import { useRouter } from 'next/router'
import React from 'react'

const PWAnavbar = () => {
  const router = useRouter()

  const handleClick = item => {
    if (item.path) {
      router.push(item.path)
    } else if (item.onClick) {
      item.onClick()
    }
  }

  const NAV_USER = [
    { label: 'Home', path: ROUTES.USER_FEED, icon: <AiFillHome size={24} /> },
    { label: 'Search', path: ROUTES.EXPLORE, icon: <Icons.ISearch size={24} /> },
    { label: 'Explore', path: ROUTES.USER_DASHBOARD, icon: <Icons.IMapPins size={24} /> },
    { label: 'Tickets', path: ROUTES.USER_TICKETS, icon: <Icons.TicketIcon size={24} /> },
    { label: 'Profile', path: ROUTES.USER_PROFILE, icon: <Icons.UserCircle size={24} /> },
  ]

  return (
    <div className={cn('btm-nav', 'bg-base-100 lg:hidden')}>
      {NAV_USER.map(item => (
        <button
          className={router?.pathname === item.path ? 'active text-secondary' : ''}
          key={item.label}
          onClick={() => handleClick(item)}>
          {item.icon}
        </button>
      ))}
    </div>
  )
}

export default PWAnavbar
