import cn from 'clsx'

const Container = ({ className = '', reference = null, children }) => {
  return (
    <div className={cn('container', 'mx-auto', className)} ref={reference}>
      {children}
    </div>
  )
}

export default Container
