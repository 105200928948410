import { FaChildren } from '@react-icons/all-files/fa6/FaChildren'
import { FaPeopleCarry } from '@react-icons/all-files/fa/FaPeopleCarry'
import { FaWifi } from '@react-icons/all-files/fa/FaWifi'
import { GiForkKnifeSpoon } from '@react-icons/all-files/gi/GiForkKnifeSpoon'
import { GiGraduateCap } from '@react-icons/all-files/gi/GiGraduateCap'
import { GiMagicSwirl } from '@react-icons/all-files/gi/GiMagicSwirl'
import { HiOutlineBriefcase } from '@react-icons/all-files/hi/HiOutlineBriefcase'
import { IoIosFitness } from '@react-icons/all-files/io/IoIosFitness'
import { PiBeerSteinFill } from '@react-icons/all-files/pi/PiBeerSteinFill'
import { PiMusicNotesBold } from '@react-icons/all-files/pi/PiMusicNotesBold'
import { TbBuildingCommunity } from '@react-icons/all-files/tb/TbBuildingCommunity'
import Icons from 'consts/Icons'

const EventSlugToTypeMap = {
  'music-events': 'Music Events',
  'comedy-events': 'Comedy Events',
  'pubs-clubs-events': 'Pubs & Clubs Events',
  'arts-culture-events': 'Arts & Culture Events',
  'sports-fitness-events': 'Sports & Fitness Events',
  'food-drink-events': 'Food & Drink Events',
  'business-networking-events': 'Business & Networking Events',
  'charity-fundraising-events': 'Charity & Fundraising Events',
  'community-events': 'Community Events',
  'education-training-events': 'Education & Training Events',
  'online-events': 'Online Events',
  'kids-events': 'Kids Events',
}

const EventTypeData = [
  {
    type: 'Music Events',
    label: 'Music',
    slug: 'music-events',
    subcategories: [
      'Live Bands',
      'Music festivals',
      'DJ nights',
      'Battle of the bands',
      'Karaoke nights',
      'Open mic nights',
      'Music workshops',
      'Music classes',
      'Music lessons',
      'Music competitions',
      'Music recitals',
      'Music concerts',
      'Music shows',
      'Music performances',
      'Music gigs',
    ],
    icon: <PiMusicNotesBold className='h-full w-full' />,
  },
  {
    type: 'Comedy Events',
    label: 'Comedy',
    slug: 'comedy-events',
    subcategories: [
      'Stand-up comedy',
      'Comedy shows',
      'Improvisational comedy',
      'Comedy workshops',
      'Comedy classes',
      'Comedy competitions',
    ],
    icon: <Icons.ILaugh className='h-full w-full' />,
  },
  {
    type: 'Pubs & Clubs Events',
    label: 'Pubs & Clubs',
    slug: 'pubs-clubs-events',
    subcategories: [
      'Quiz Night',
      'Food events',
      'Theme nights',
      'Fancy dress',
      'Cocktails',
      'Caberet',
      'Drag show',
    ],
    icon: <PiBeerSteinFill className='h-full w-full' />,
  },
  {
    type: 'Arts & Culture Events',
    label: 'Arts & Culture',
    slug: 'arts-culture-events',
    subcategories: [
      'Museum exhibitions',
      'Art galleries',
      'Art shows',
      'Film screenings',
      'Theatre plays',
      'Poetry readings',
    ],
    icon: <GiMagicSwirl className='h-full w-full' />,
  },
  {
    type: 'Sports & Fitness Events',
    label: 'Sports & Fitness',
    slug: 'sports-fitness-events',
    subcategories: [
      'Marathons',
      'Football Matches',
      'Yoga Classes',
      'Gym Sessions',
      'Cycling Events',
    ],
    icon: <IoIosFitness className='h-full w-full' />,
  },
  {
    type: 'Food & Drink Events',
    label: 'Food & Drink',
    slug: 'food-drink-events',
    subcategories: [
      'Wine tasting',
      'Beer tasting',
      'Food tasting',
      'Cooking classes',
      'Food fairs',
      'Restaurant pop-ups',
      'Craft beer festivals',
    ],
    icon: <GiForkKnifeSpoon className='h-full w-full' />,
  },
  {
    type: 'Business & Networking Events',
    label: 'Business & Networking',
    slug: 'business-networking-events',
    subcategories: ['Conferences', 'Workshops', 'Meetups', 'Seminars', 'Job fairs'],
    icon: <HiOutlineBriefcase className='h-full w-full' />,
  },
  {
    type: 'Charity & Fundraising Events',
    label: 'Charity & Fundraising',
    slug: 'charity-fundraising-events',
    subcategories: ['Benefit concerts', 'Charity runs', 'Auctions', 'Galas', 'Charity walks'],
    icon: <FaPeopleCarry className='h-full w-full' />,
  },
  {
    type: 'Community Events',
    label: 'Community',
    slug: 'community-events',
    subcategories: [
      'Street festivals',
      'Carnivals',
      'Parades',
      'Block parties',
      'Holiday events',
      'Pop Up Shoppings',
    ],
    icon: <TbBuildingCommunity className='h-full w-full' />,
  },

  {
    type: 'Education & Training Events',
    label: 'Education & Training',
    slug: 'education-training-events',
    subcategories: [
      'Workshops',
      'Webinars',
      'Seminars',
      'Training courses',
      'Certification programs',
    ],
    icon: <GiGraduateCap className='h-full w-full' />,
  },
  {
    type: 'Online Events',
    label: 'Online',
    slug: 'online-events',
    subcategories: [
      'Webinars',
      'Workshops',
      'Online Conference or Summit',
      'Virtual Trade shows',
      'Online concert or performance',
      'Virtual Networking',
      'Online classes or courses',
    ],
    icon: <FaWifi className='h-full w-full' />,
  },
  {
    type: 'Kids Events',
    label: 'Kids',
    slug: 'kids-events',
    subcategories: [
      'Workshops',
      'Games & Activities',
      'Music, Concert or performance',
      'Classes or courses',
      'Health & Wellness',
      'Travel & Outdoors',
      'Others',
    ],
    icon: <FaChildren className='h-full w-full' />,
  },
]

export { EventTypeData, EventSlugToTypeMap }
