/**
 * The goal is to have two logging mechanism so that devlopment logs do not show up in production.
 * console.log - only for development
 * console.info, .warn, .Error - for any environment (ideally Production)
 */

const originalConsoleLog = console.log

const ALLOW_DEV_LOGS = process.env.NEXT_PUBLIC_APP_ENV !== 'production' // allow dev & emulator

console.log = (...args) => {
  if (!ALLOW_DEV_LOGS) return

  originalConsoleLog(...args)
}
