const getCFUrl = (functionName, CF_BASE) => {
  // console.log('@getCFUrl -> process[dot]env[dot]NEXT_PUBLIC_APP_ENV ->', process.env.NEXT_PUBLIC_APP_ENV)

  switch (process.env.NEXT_PUBLIC_APP_ENV) {
    case 'emulator':
      return `${CF_BASE.EMULATOR}/${functionName}` // uncomment this to work with emulators

    case 'development':
      return `${CF_BASE.DEVELOPMENT}/${functionName}`

    case 'production':
      return `${CF_BASE.PRODUCTION}/${functionName}`

    default:
      return `${CF_BASE.DEVELOPMENT}/${functionName}`
  }
}

export default getCFUrl
