// #############################################################################
// #                                BUYER                                      #
// #############################################################################
import { FETCH_LIMIT } from 'consts/Firebase'
import MSG from 'consts/Messages'
import {
  collection,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where,
} from 'firebase/firestore'
import { toast } from 'react-toastify'
import { db } from 'services/FirebaseClient'
import { sanitizeObjForUpload } from 'utils/Objects'

// #############################################################################
// #                                CREATE                                     #
// #############################################################################
export const createOrderDoc = async (orderId, payload) => {
  const sanitizedPayload = sanitizeObjForUpload(payload)
  const docRef = doc(db, 'orders', orderId)

  try {
    await setDoc(docRef, { ...sanitizedPayload, createdAt: serverTimestamp() })
  } catch (err) {
    console.log('Error creating new order', err)
    throw err
  }
}

// #############################################################################
// #                                READ                                       #
// #############################################################################
export const readPublicOrders = async (fetchLimit = FETCH_LIMIT) => {
  // if (!eventId) return

  const q = query(
    collection(db, 'orders'),
    where('isCancelled', '==', false),
    where('event.isPrivate', '==', false),
    // where('eventId', '==', eventId),
    orderBy('createdAt', 'desc'),
    limit(fetchLimit)
  )

  try {
    const res = await getDocs(q)
    const orders = res.docs.map(doc => doc.data())

    return orders
  } catch (error) {
    toast.error(MSG.ERROR.BUYER.FETCH_ORDER_DETAILS, { toastId: 'get-order-failed' })
    console.error('🚩 @readPublicOrders', error.message)

    return []
  }
}

export const readPublicOrdersByEventId = async (eventId, limit = FETCH_LIMIT) => {
  if (!eventId) return

  const q = query(
    collection(db, 'orders'),
    where('isCancelled', '==', false),
    where('eventId', '==', eventId),
    limit(limit)
  )

  try {
    const res = await getDocs(q)
    const orders = res.docs.map(doc => doc.data())

    return orders
  } catch (error) {
    toast.error(MSG.ERROR.BUYER.FETCH_ORDER_DETAILS, { toastId: 'get-order-failed' })
    console.error('🚩 @readPublicOrdersByEventId', error.message)
  }
}

export const readOrdersByBuyerId = async (userId, callback = () => {}) => {
  if (!userId) return null

  const q = query(collection(db, 'orders'), where('user.userId', '==', userId), limit(FETCH_LIMIT))

  try {
    onSnapshot(q, snapshot => {
      const purchaseOrders = snapshot.docs.map(doc => doc.data())
      callback(purchaseOrders)
    })
  } catch (error) {
    console.error('Error @readOrdersByBuyerId', error)
  }
}

// #############################################################################
// #                                UPDATE                                     #
// #############################################################################

// #############################################################################
// #                                DELETE                                     #
// #############################################################################
