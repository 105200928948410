import { useDispatch } from 'react-redux'
import { resetLoader, setLoading } from 'store/reducers'

const useGlobalLoader = () => {
  const dispatch = useDispatch()

  const loading = {
    start: (message = '') => dispatch(setLoading({ isLoading: true, message })),
    remove: () => dispatch(resetLoader()),
  }

  return { loading }
}

export default useGlobalLoader
