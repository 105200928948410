import { MdVerified } from '@react-icons/all-files/md/MdVerified'
import React from 'react'

const VerifiedBadge = () => {
  return (
    <span className=''>
      <MdVerified size={20} color='#E8336A' />
    </span>
  )
}

export default VerifiedBadge
