import cn from 'clsx'
import Trivia from 'components/molecules/Trivia'

const MainContainer = ({ children, className }) => {
  return (
    <main
      className={cn(
        'mt-16 md:mt-16', // gap from header
        'mb-24 md:mb-24', // gap from nav bar on mobile view
        'flex flex-col',
        'lg:gap-18 gap-10 md:gap-16',
        className
      )}
      id='AppMain'>
      {children}
      <Trivia />
    </main>
  )
}

export default MainContainer
