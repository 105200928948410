import { combineReducers } from '@reduxjs/toolkit'

import bigActionSConfirmationReducer from './bigActionSConfirmation'
import chartReducer from './charts'
import confirmation from './confirmation'
import eventsReducer from './events'
import loadingReducer from './loading'
import ordersReducer from './orders'
import uiReducer from './ui'
import userReducer from './user'

// ------------- Root Reducer -------------
const rootReducer = combineReducers({
  ui: uiReducer,
  user: userReducer,
  events: eventsReducer,
  orders: ordersReducer,
  charts: chartReducer,
  bigActionsConfirmation: bigActionSConfirmationReducer,
  loading: loadingReducer,
  confirmation: confirmation,
})

// https://github.com/kirill-konshin/next-redux-wrapper/blob/master/packages/demo-redux-toolkit/store.ts
// Store setup
// const reducers = {
// [subjectPageSlice.name]: subjectPageSlice.reducer,
// [detailPageSlice.name]: detailPageSlice.reducer,
// [gippPageSlice.name]: gippPageSlice.reducer,
// [systemSlice.name]: systemSlice.reducer,
// [pokemonApi.reducerPath]: pokemonApi.reducer,
// }

// Export all available actions
export * from './events'
export * from './loading'
export * from './bigActionSConfirmation'
export * from './confirmation'
export * from './orders'
export * from './ui'
export * from './user'
export * from './charts'

// Export RootReducer
export default rootReducer
