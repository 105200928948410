import { IoOpen } from '@react-icons/all-files/io5/IoOpen'
import Button from 'components/atoms/Button'
import ROUTES from 'consts/Routes'
import { getBaseUrlFor } from 'utils/Url'

const HostsActiononEvents = ({ isMinimal, isOwnerOfEvent }) => {
  if (isMinimal || !isOwnerOfEvent) return null

  return (
    <div className='absolute bottom-4 right-4 flex gap-2'>
      <div className='tooltip' data-tip='Edit Event'>
        <Button
          className='btn-secondary btn-md border-none bg-opacity-75 backdrop-blur-md'
          // icon={<FiEdit2 size={20} />}
          icon={<IoOpen size={20} />}
          onClick={e => {
            e.preventDefault()
            // TODO: Fix overview and edit pages
            // window.open(getBaseUrlFor('partners')+ROUTES.HOST_EVENT_EDIT.replace(':eventId', event?.eventId), '_blank')
            window.open(getBaseUrlFor('partners') + ROUTES.HOST_EVENTS, '_blank')
          }}></Button>
      </div>
    </div>
  )
}
export default HostsActiononEvents
