import Button from 'components/atoms/Button'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLatestAlert } from 'services/client/common/alerts'
import { markAlertsAsViewed } from 'store/reducers'
import { getDayjsObj } from 'utils/DateTime'

const AlertBanner = () => {
  const dispatch = useDispatch()

  const viewedAlerts = useSelector(state => state?.pr?.ui?.viewedAlertsTimeStamps)
  const [alert, setAlert] = useState(null)

  useEffect(() => {
    const unsubscribe = () => {
      getLatestAlert(latestAlert => {
        const previousAlert = viewedAlerts?.includes(
          new Date(getDayjsObj(latestAlert?.savedAt)).toString()
        )
        if (latestAlert && !previousAlert) {
          setAlert(latestAlert)
        }
      })
    }

    unsubscribe()

    // Clean up the listener when the component unmounts
    return () => unsubscribe()
  }, [])

  const handleCloseAlert = () => {
    if (alert) {
      dispatch(markAlertsAsViewed(new Date(getDayjsObj(alert?.savedAt)).toString()))
    }
    setAlert(null)
  }

  if (!alert) {
    return null
  }

  if (!alert?.isFullPage)
    return (
      <div
        role='alert'
        className='alert fixed left-0 top-14 z-50 flex w-full max-w-xl flex-col items-start justify-center shadow-lg sm:left-2 sm:flex-row sm:items-center'>
        <div className='text-left'>
          <h3 className='font-bold'>{alert?.heading}</h3>
          <div className='text-xs'>{alert?.description}</div>
        </div>
        <Button className='btn btn-secondary btn-sm' onClick={handleCloseAlert}>
          Got it
        </Button>
      </div>
    )

  return (
    <div className='hero fixed left-0 top-0 z-50 min-h-screen bg-base-200 opacity-95'>
      <div className='hero-content text-center'>
        <div className='max-w-2xl'>
          <h1 className='text-5xl font-bold'>{alert?.heading}</h1>
          <p className='py-4'>{alert?.description}</p>
          <Button className='btn btn-primary' onClick={handleCloseAlert}>
            Got it
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AlertBanner
